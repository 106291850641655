import { FC, useEffect, useRef } from 'react'
import { useColorVar, useStyles } from '@/hooks/styles'
import styles from '@/components/vis/BoxPlot.module.scss'
import { Chart } from '@antv/g2'
import { useDataOverviewStore } from '@/hooks'
import { observer } from 'mobx-react'
import { IAnnotationData } from '@/store/dataOverViewStore'

export const BoxPlot: FC = observer(() => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const containerRef = useRef()
  const dataOverview = useDataOverviewStore()

  const dataFilter = (selectedFeatures: string[], data: IAnnotationData[]) => {
    const result = data.filter((ele, index, arr) =>
      selectedFeatures.includes(ele.x)
    )
    // console.log(result)
    return result.length > 0 ? result : data
  }

  useEffect(() => {
    // console.log(annotationData)
    const container = containerRef.current as HTMLElement
    container.innerHTML = ''

    const chart = new Chart({
      container: container,
      // autoFit: true,
      width: 400,
      height: 270,
      paddingTop: 24,
      paddingBottom: 10,
      paddingLeft: 12,
      theme: 'classicDark',
    })

    chart.data(
      dataFilter(
        dataOverview.selectedFeature,
        dataOverview.annotationData as IAnnotationData[]
      )
    )

    chart
      .density()
      .data({
        transform: [
          {
            type: 'kde',
            field: 'y',
            groupBy: ['x', 'species'],
          },
        ],
      })
      .encode('x', 'x')
      .encode('y', 'y')
      .encode('series', 'species')
      .encode('color', 'species')
      .encode('size', 'size')
      .tooltip(false)

    chart
      .boxplot()
      .encode('x', 'x')
      .encode('y', 'y')
      .encode('series', 'species')
      .encode('color', 'species')
      .encode('shape', 'violin')
      .style('opacity', 0.5)
      .style('strokeOpacity', 0.5)
      .style('point', false)

    chart.render()

    // 在组件卸载时销毁图表实例，释放内存
    return () => chart.destroy()
  }, [dataOverview.selectedFeature])

  return <div ref={containerRef} {...styleClass(['layout'])}></div>
})
