import { FC, useEffect, useRef } from 'react'
import { useColorVar, useStyles } from '@/hooks/styles'
import styles from '@/components/vis/ArcLine.module.scss'
import { Chart } from '@antv/g2'

interface IArcLine {
  mode: 'video' | 'audio'
}
export const ArcLine: FC<IArcLine> = ({ mode }) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const containerRef = useRef()

  const videoData = [
    { source: '叙事节奏', target: '叙事内容', value: 91 },
    { source: '叙事节奏', target: '动作能量', value: 86 },
    { source: '动作能量', target: '叙事内容', value: 86 },
    { source: '动作幅度', target: '动作能量', value: 41 },
    { source: '动作幅度', target: '叙事节奏', value: 37 },
    { source: '动作幅度', target: '叙事内容', value: 33 },
    { source: '色彩轻重', target: '形象亲缘', value: 27 },
  ]

  const audioData = [
    { source: '和声丰富', target: '旋律起伏', value: 79 },
    { source: '和声丰富', target: '节奏速度', value: 80 },
    { source: '旋律起伏', target: '节奏速度', value: 76 },
    { source: '旋律起伏', target: '音色冷暖', value: 22 },
    { source: '旋律起伏', target: '和声和谐', value: 25 },
    { source: '音色冷暖', target: '和声和谐', value: 83 },
    { source: '音色明暗', target: '和声丰富', value: 43 },
    { source: '音色明暗', target: '旋律起伏', value: 33 },
    { source: '音色明暗', target: '节奏速度', value: 43 },
  ]

  useEffect(() => {
    const container = containerRef.current as HTMLElement
    container.innerHTML = ''

    const chart = new Chart({
      container: container,
      autoFit: true,
      theme: 'classicDark',
      padding: 12,
    })

    chart
      .chord()
      .data({
        value: { links: mode === 'video' ? videoData : audioData },
      })
      .layout({
        nodeWidthRatio: 0.05,
      })
      .scale('color', { palette: 'pastel1' })
      // .scale('color', { range: ['#DADAEB', '#BCBDDC', '#9E9AC8', '#807DBA'] })
      .label({ fontSize: 10 })
      .style('linkFillOpacity', 0.5)

    chart.render()
  }, [mode])
  return <div ref={containerRef} {...styleClass(['layout'])}></div>
}
