import { CSSProperties, FC, useEffect, useRef, useState } from 'react'
import styles from './DataOverview.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { useNavigate } from 'react-router-dom'
import { theme, Tooltip, Tag, Radio, Segmented } from 'antd'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'
import { TreeGraph } from '@/components/vis/TreeGraph'
import { HeatMapForAnnotationFeature } from '@/components/vis/HeatMapForAnnotationFeature'
import { BoxPlot } from '@/components/vis/BoxPlot'
import { ScatterPlot } from '@/components/vis/ScatterPlot'
import { v4 as uuidv4 } from 'uuid'
import { observer } from 'mobx-react'
import { useDataOverviewStore } from '@/hooks'
import { Chart } from '@antv/g2'
import { ArcLine } from '@/components/vis/ArcLine'
import { BulletBar } from '@/components/vis/BulletBar'
import { Button } from '@arco-design/web-react'

const DataOverview: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const navigation = useNavigate()

  const {
    token: { colorBgContainer },
  } = theme.useToken()
  return (
    <>
      <div {...styleClass(['layout'])}>
        <div {...styleClass(['header'])}>
          <div {...styleClass(['header-title'])}>资源概况</div>
        </div>

        <div {...styleClass(['content'])}>
          <div
            {...styleClass(['card', 'card-left'], {
              background: colorBgContainer,
            })}
          >
            <div {...styleClass(['title'])}>数据统计</div>
            <div {...styleClass(['statics-grid'])}>
              <Statics title={'视听资产总数'} value={'10,950'} />
              <Statics title={'音视频总时间跨度'} value={'972.5h'} />
              <Statics title={'涵盖感知特征条目'} value={'2x7 + 2'} />
            </div>
            <div {...styleClass(['divider-title'])}>
              <div {...styleClass(['divider-title-text'])}>
                {'音视频片段总览'}
              </div>
              <div {...styleClass(['divider-title-line'])}></div>
            </div>
            <div {...styleClass(['legend1'])}>
              <div {...styleClass(['legend1-item'])}>
                <img
                  src={
                    'https://s3.bmp.ovh/imgs/2024/11/28/869feae40a81b8f7.png'
                  }
                  width={8}
                />
                <div {...styleClass(['legend1-item-text'])}>
                  {'配对音视频: 6432'}
                </div>
              </div>
              <div {...styleClass(['legend1-item'])}>
                <img
                  src={
                    'https://s3.bmp.ovh/imgs/2024/11/28/61b897031969ab56.png'
                  }
                  width={8}
                />
                <div {...styleClass(['legend1-item-text'])}>
                  {'独立音频: 4096'}
                </div>
              </div>
            </div>

            <BulletBar />

            <div {...styleClass(['divider-title'])}>
              <div {...styleClass(['divider-title-text'])}>
                {'视听特征构成'}
              </div>
              <div {...styleClass(['divider-title-line'])}></div>
            </div>

            <TreeGraph />
          </div>

          <div
            {...styleClass(['card', 'card-right'], {
              background: colorBgContainer,
            })}
          >
            <div {...styleClass(['title'])}>{'视听感知表现'}</div>
            <div {...styleClass(['relation'])}>
              <div {...styleClass(['relation-heat-map'])}>
                <GraphTitle
                  text={'标注特征-底层特征 相关性'}
                  {...styleClass([], { marginBottom: 12 })}
                />
                <HeatMapForAnnotationFeature />
              </div>

              <div {...styleClass(['relation-box-plot'])}>
                <GraphTitle
                  text={'标注主观分布'}
                  {...styleClass([], { marginBottom: 6 })}
                />
                <BoxPlot />
              </div>
            </div>

            <div {...styleClass(['distribution'])}>
              <ScatterPlot />
            </div>

            <div {...styleClass(['divider-title'], { marginTop: 8 })}>
              <div {...styleClass(['divider-title-text'])}>
                {'感知特征集合'}
              </div>
              <div {...styleClass(['divider-title-line'])}></div>
            </div>

            <FeatureList />
          </div>
        </div>
      </div>
    </>
  )
}

export default DataOverview

interface IStatics {
  title?: string
  value?: string
}

const Statics: FC<IStatics> = ({ title, value }) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  return (
    <div {...styleClass(['statics-layout'])}>
      <div {...styleClass(['statics-title'])}>{title}</div>
      <div {...styleClass(['statics-value'])}>{value}</div>
    </div>
  )
}

interface IGraphTitle {
  text?: string
  className?: string
  style?: CSSProperties
}

const GraphTitle: FC<IGraphTitle> = ({ text = '', className, style }) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)

  return (
    <div {...styleClass([], { display: 'flex', flexDirection: 'row' })}>
      <motion.div
        {...styleClass(['graph-title'], {}, { className, style })}
        whileHover={{ background: '#404951' }}
        transition={{ ...motionEasing }}
      >
        <div {...styleClass(['graph-title-text'])}>{text}</div>
      </motion.div>
    </div>
  )
}

const FeatureList: FC = observer(() => {
  const c = useColorVar()
  const styleClass = useStyles(styles)

  const dataOverview = useDataOverviewStore()
  const [mode, setMode] = useState<'video' | 'audio'>('video')

  return (
    <div {...styleClass(['feature-list-layout'])}>
      <div {...styleClass(['feature-list-header'])}>
        <div {...styleClass(['feature-list-header-left'])}>
          <div {...styleClass(['feature-list-header-name'])}>{'特征名称'}</div>
          <div {...styleClass(['feature-list-header-accuracy'])}>
            {'人类偏好对齐'}
          </div>
        </div>

        <div {...styleClass(['feature-list-header-right'])}>
          <div {...styleClass(['feature-list-header-from'])}>{'建模方式'}</div>
          <div {...styleClass(['feature-list-header-time'])}>{'时序'}</div>
          <div {...styleClass(['feature-list-header-distribution'])}>
            {'样本分布'}
          </div>
        </div>

        <div {...styleClass(['feature-list-header-sider'])}>
          <div {...styleClass(['feature-list-header-sider-text'])}>
            {'特征关联'}
          </div>
          <Segmented
            size={'middle'}
            options={['视频', '音频']}
            value={mode === 'video' ? '视频' : '音频'}
            onChange={(value) => setMode(value === '视频' ? 'video' : 'audio')}
          />
        </div>
      </div>

      <div {...styleClass(['feature-list-content'])}>
        <div {...styleClass(['feature-list-content-left'])}>
          {dataOverview.featureInfo
            .filter((feature) => feature.type === mode)
            .map((item, index) => (
              <div key={uuidv4()} {...styleClass(['feature-list-left-item'])}>
                <div {...styleClass(['feature-list-left-item-name'])}>
                  {item.name}
                </div>
                <ProgressBar progressV={item.accuracyLevel} />
              </div>
            ))}
        </div>

        <div {...styleClass(['feature-list-content-right'])}>
          {dataOverview.featureInfo
            .filter((feature) => feature.type === mode)
            .map((item, index) => (
              <div key={uuidv4()} {...styleClass(['feature-list-right-item'])}>
                <Radio.Group
                  value={item.from}
                  size="small"
                  {...styleClass([], { flexShrink: 0 })}
                >
                  <Radio.Button value="计算">{'计算'}</Radio.Button>
                  <Radio.Button value="标注">{'标注'}</Radio.Button>
                </Radio.Group>
                <Tag
                  {...styleClass([], {
                    flexShrink: 0,
                    margin: '0 0 0 16px',
                    opacity: item.time ? 1 : 0.15,
                  })}
                  bordered={false}
                >
                  {'时序'}
                </Tag>

                <River data={item.score_range_distribution} />
              </div>
            ))}
        </div>

        <ArcLine mode={mode} />
      </div>
    </div>
  )
})

interface IProgressBar {
  progressV: number // 1 2 3 4 5
}
const ProgressBar: FC<IProgressBar> = ({ progressV }) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)

  return (
    <div {...styleClass(['progress-bar'])}>
      {[1, 2, 3, 4, 5].map((item, index) => (
        <div
          key={uuidv4()}
          {...styleClass(['progress-bar-item'], {
            background:
              progressV >= item
                ? 'linear-gradient(269deg, #9E9AC8 0%, #625BB2 165.62%)'
                : '#282E34',
          })}
        ></div>
      ))}
    </div>
  )
}

interface IRiver {
  data?: number[]
}
const River: FC<IRiver> = ({ data }) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const containerRef = useRef()

  useEffect(() => {
    const container = containerRef.current as HTMLElement
    container.innerHTML = ''
    const chart = new Chart({
      container: container,
      padding: 0,
      marginBottom: 4,
      marginTop: 2,
      width: 200,
      height: 28,
    })

    chart.data(data)

    chart
      .area()
      .encode('x', (_, idx) => idx)
      .encode('y', (d) => d)
      .encode('shape', 'smooth')
      .scale('y', { zero: true })
      .style(
        'fill',
        'linear-gradient(-90deg, rgb(35, 40, 46) 0%, #D9D9D9 100%)'
      )
      .style('fillOpacity', 0.6)
      .tooltip(false)
      .axis(false)

    chart.render()
  }, [data])
  return (
    <div
      ref={containerRef}
      {...styleClass(['feature-list-right-item-river'])}
    ></div>
  )
}
