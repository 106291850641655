import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './AffectiveComputing.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { Button, theme, Upload, Flex, Spin } from 'antd'
import { token, uploadUrl } from '@/config'
import { Chart } from '@antv/g2'
import axios from 'axios'

const AffectiveComputing: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const videoRef = useRef(null)
  const [inputUrl, setInputUrl] = useState<string>('')
  const [ifLoading, setIfLoading] = useState<boolean>(false)

  const getAV = async (type: 'video' | 'audio') => {
    setIfLoading(true)
    if (type === 'video') {
      await axios
        .post(
          'https://inlab-server.littleor.cn/multimodal-emotion/globalPrediction',
          {
            video_url: [inputUrl],
          }
        )
        .then((res) => {
          console.log(res.data.data.labels)
          setIfLoading(false)
          return
        })
        .catch((err) => {
          console.log(err)
          setIfLoading(false)
          return
        })
    } else {
      await axios
        .post('https://inlab-server.littleor.cn/music-emotion/prediction', {
          url: inputUrl,
        })
        .then((res) => {
          console.log(res.data.data.labels)
          setIfLoading(false)
          return
        })
        .catch((err) => {
          console.log(err)
          setIfLoading(false)
          return
        })
    }
  }
  useEffect(() => {
    const videoPlayer = videoRef.current
    videoPlayer.load()
    if (inputUrl !== '') {
      const temp = inputUrl.split('.')
      const format = temp[temp.length - 1]
      getAV(['mp4', 'avi', 'mov'].includes(format) ? 'video' : 'audio')
    } else {
      // TODO:移除文件后，清空state的可视化数据
    }
  }, [inputUrl])
  return (
    <div {...styleClass(['layout'], { background: colorBgContainer })}>
      <div {...styleClass(['title'])}>
        <div {...styleClass(['title-text'])}>
          {'受众的情感计算与预测模型研究'}
        </div>
      </div>
      <div {...styleClass(['container'])}>
        <div {...styleClass(['input'])}>
          <Upload
            headers={{ Authorization: token }}
            action={uploadUrl}
            maxCount={1}
            onChange={(file) => {
              if ('response' in file.file) {
                const url = file.file.response.data as string
                console.log('video', url)
                setInputUrl(url)
              }
              if (file.fileList.length === 0) {
                // 表示移除文件
                setInputUrl('')
              }
            }}
          >
            <Button>{'上传媒体文件'}</Button>
          </Upload>
          <div {...styleClass(['video-container'])}>
            <video
              ref={videoRef}
              style={{ width: '100%', height: '100%' }}
              className="video-js"
              controls
              preload="auto"
              autoPlay
            >
              <source src={inputUrl} type="video/mp4"></source>
            </video>
          </div>
        </div>

        <Flex {...styleClass(['output-vis'])}>
          <Spin spinning={ifLoading} size={'large'}>
            <div {...styleClass(['output-vis-block'])}>
              <ScatterPlot />
            </div>
            <div {...styleClass(['output-vis-block'])}>
              <LineChart />
            </div>
          </Spin>
        </Flex>
      </div>
    </div>
  )
}

export default AffectiveComputing

const ScatterPlot: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const containerRef = useRef()
  const test = `
    快乐：情感效价 80，唤醒度 70。快乐通常是一种积极的情感体验，具有较高的正面效价，同时能引起一定程度的生理和心理唤醒，让人感到充满活力。
    悲伤：情感效价 20，唤醒度 40。悲伤是负面情感，效价较低，其唤醒度一般不会太高，处于一种相对低落、消沉的状态。
    惊讶：情感效价 50，唤醒度 80。惊讶本身不带有明显的正负性，但能迅速引起高度的唤醒，使注意力瞬间集中。
    兴奋：情感效价 70，唤醒度 90。兴奋是一种积极的高唤醒状态，具有较高的正面效价，伴随着强烈的生理和心理激活。
    中性：情感效价 50，唤醒度 30。中性情感表示既非积极也非消极，处于一种平静、稳定的状态，唤醒度较低。
    愤怒：情感效价 30，唤醒度 80。愤怒是负面情感，具有一定的攻击性，往往伴随着较高的唤醒度，身体会处于一种应激状态。
    厌恶：情感效价 30，唤醒度 50。厌恶是对事物的反感和排斥，效价为负，唤醒度适中，主要表现为心理上的抵触。
    恐惧：情感效价 20，唤醒度 80。恐惧是一种强烈的负面情感，能引发高度的唤醒，通常伴随着逃避或防御的冲动。
`
  const data = [
    { v: 55, a: 27, size: 4, emotion: '快乐' },
    { v: 85, a: 45, size: 7, emotion: '快乐' },
    { v: 90, a: 0, size: 1, emotion: '快乐' },
    { v: 75, a: 35, size: 12, emotion: '快乐' },
    { v: 88, a: 48, size: 3, emotion: '快乐' },
    { v: 68, a: -10, size: 2, emotion: '快乐' },
    { v: 92, a: 32, size: 8, emotion: '快乐' },
    { v: 72, a: 62, size: 5, emotion: '快乐' },
    { v: 30, a: 66, size: 3, emotion: '快乐' },
    { v: 80, a: 50, size: 3, emotion: '快乐' },
    { v: 84, a: 44, size: 9, emotion: '快乐' },
    { v: -55, a: -15, size: 8, emotion: '悲伤' },
    { v: -65, a: -25, size: 3, emotion: '悲伤' },
    { v: -58, a: -22, size: 6, emotion: '悲伤' },
    { v: -62, a: -18, size: 4, emotion: '悲伤' },
    { v: -60, a: -20, size: 7, emotion: '悲伤' },
    { v: -52, a: -28, size: 10, emotion: '悲伤' },
    { v: -68, a: -12, size: 5, emotion: '悲伤' },
    { v: -75, a: -25, size: 2, emotion: '悲伤' },
    { v: -45, a: -15, size: 12, emotion: '悲伤' },
    { v: -5, a: 55, size: 4, emotion: '惊讶' },
    { v: 5, a: 65, size: 7, emotion: '惊讶' },
    { v: -2, a: 62, size: 3, emotion: '惊讶' },
    { v: 2, a: 58, size: 6, emotion: '惊讶' },
    { v: 0, a: 60, size: 8, emotion: '惊讶' },
    { v: -8, a: 68, size: 2, emotion: '惊讶' },
    { v: 8, a: 52, size: 10, emotion: '惊讶' },
    { v: -15, a: 70, size: 1, emotion: '惊讶' },
    { v: 4, a: 40, size: 12, emotion: '惊讶' },
    { v: 45, a: 80, size: 3, emotion: '兴奋' },
    { v: 55, a: 90, size: 6, emotion: '兴奋' },
    { v: 50, a: 85, size: 8, emotion: '兴奋' },
    { v: 35, a: 95, size: 2, emotion: '兴奋' },
    { v: 65, a: 75, size: 5, emotion: '兴奋' },
    { v: 3, a: -30, size: 3, emotion: '中性' },
    { v: -3, a: -50, size: 4, emotion: '中性' },
    { v: 6, a: -35, size: 5, emotion: '中性' },
    { v: -6, a: -45, size: 2, emotion: '中性' },
    { v: 9, a: -25, size: 6, emotion: '中性' },
    { v: -9, a: -55, size: 1, emotion: '中性' },
    { v: 0, a: -40, size: 7, emotion: '中性' },
    { v: 12, a: -60, size: 3, emotion: '中性' },
    { v: -12, a: -42, size: 4, emotion: '中性' },
    { v: 15, a: -22, size: 5, emotion: '中性' },
    { v: -15, a: -58, size: 2, emotion: '中性' },
    { v: 18, a: -75, size: 6, emotion: '中性' },
    { v: -18, a: -48, size: 1, emotion: '中性' },
    { v: 21, a: -20, size: 3, emotion: '中性' },
    { v: -2, a: -15, size: 4, emotion: '中性' },
    { v: -35, a: 60, size: 3, emotion: '愤怒' },
    { v: -45, a: 80, size: 4, emotion: '愤怒' },
    { v: -30, a: 75, size: 5, emotion: '愤怒' },
    { v: -50, a: 65, size: 2, emotion: '愤怒' },
    { v: -40, a: 70, size: 7, emotion: '愤怒' },
    { v: -25, a: 55, size: 1, emotion: '愤怒' },
    { v: -55, a: 85, size: 6, emotion: '愤怒' },
    { v: -50, a: -25, size: 3, emotion: '厌恶' },
    { v: -90, a: 25, size: 4, emotion: '厌恶' },
    { v: -85, a: -40, size: 5, emotion: '厌恶' },
    { v: -65, a: 40, size: 2, emotion: '厌恶' },
    { v: -75, a: 0, size: 7, emotion: '厌恶' },
    { v: -60, a: -50, size: 1, emotion: '厌恶' },
    { v: -70, a: 50, size: 6, emotion: '厌恶' },
    { v: -88, a: -15, size: 3, emotion: '厌恶' },
    { v: -62, a: 15, size: 4, emotion: '厌恶' },
    { v: -42, a: -30, size: 5, emotion: '厌恶' },
    { v: -70, a: 85, size: 3, emotion: '恐惧' },
    { v: -80, a: 93, size: 4, emotion: '恐惧' },
    { v: -65, a: 90, size: 5, emotion: '恐惧' },
    { v: -85, a: 87, size: 2, emotion: '恐惧' },
    { v: -75, a: 89, size: 7, emotion: '恐惧' },
    { v: -60, a: 91, size: 1, emotion: '恐惧' },
    { v: -90, a: 83, size: 6, emotion: '恐惧' },
    { v: -55, a: 82, size: 3, emotion: '恐惧' },
    { v: -55, a: 82, size: 24, emotion: '样本实例' },
  ]
  useEffect(() => {
    const container = containerRef.current as HTMLElement
    container.innerHTML = ''
    const chart = new Chart({
      container: container,
      theme: 'classicDark',
      autoFit: true,
      data: data,
    })

    chart
      .range()
      .data([
        { x: [-100, 0], y: [-100, 0], region: '1' },
        { x: [-100, 0], y: [0, 100], region: '2' },
        { x: [0, 100], y: [-100, 0], region: '2' },
        { x: [0, 100], y: [0, 100], region: '1' },
      ])
      .encode('x', 'x')
      .encode('y', 'y')
      .style('fill', (d) => (d.region === '1' ? '#d8d0c0' : '#a3dda1'))
      .style('fillOpacity', 0.2)
      .animate('enter', { type: 'fadeIn' })

    chart
      .point()
      .encode('x', 'v')
      .encode('y', 'a')
      .encode('size', 'size')
      .encode('color', 'emotion')
      .encode('shape', 'point')
      // .scale('color', {
      //   range: ['#ffd500', '#82cab2', '#193442', '#d18768', '#7e827a'],
      // })
      .scale('size', { range: [2, 16] })
      .style('stroke', (data) => {
        if (data.emotion === '样本实例') {
          return '#fff'
        } else {
          return '#666'
        }
      })
      .style('fillOpacity', (data) => {
        if (data.emotion === '样本实例') {
          return 1
        } else {
          return 0.7
        }
      })
      .axis('x', { title: '情感效价' })
      .axis('y', { title: '情感唤醒' })
      .legend('size', false)

      .tooltip([
        { channel: 'x', valueFormatter: '.1f' },
        { channel: 'y', valueFormatter: '.1f' },
      ])

    chart.render()
  }, [])
  return <div ref={containerRef} {...styleClass(['scatterplot'])}></div>
}

const LineChart: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const containerRef = useRef()

  const data = [
    { index: '1', type: 'a', value: -30 },
    { index: '1', type: 'v', value: 20 },
    { index: '2', type: 'a', value: -35 },
    { index: '2', type: 'v', value: 25 },
    { index: '3', type: 'a', value: -45 },
    { index: '3', type: 'v', value: 10 },
    { index: '4', type: 'a', value: 0 },
    { index: '4', type: 'v', value: -10 },
    { index: '5', type: 'a', value: 20 },
    { index: '5', type: 'v', value: -80 },
  ]

  useEffect(() => {
    const container = containerRef.current as HTMLElement
    container.innerHTML = ''
    const chart = new Chart({
      container: container,
      theme: 'classicDark',
      autoFit: true,
      data: data,
    })

    chart
      .data(data)
      .encode('x', 'index')
      .encode('y', 'value')
      .encode('color', 'type')
      .scale('x', {
        range: [0, 1],
      })
      .scale('y', {
        domain: [-100, 100], // 设置 y 轴坐标范围为 -100 到 100
        nice: true,
      })
      .axis('y', { title: '效价与唤醒度', labelFormatter: (d) => d })
      .axis('x', { title: '时间' })

    chart.line().encode('shape', 'smooth')

    chart.point().encode('shape', 'point').tooltip(false)

    chart.render()
  }, [])
  return <div ref={containerRef} {...styleClass(['linechart'])}></div>
}
