import { makeAutoObservable } from 'mobx'
import { RootContext } from '@/App.context'
import AppStore from '@/store/app'
import UserStore from './userStore'
import VideoStore from './videoStore'
import DataOverviewStore from './dataOverViewStore'

export class RootStore {
  public context: RootContext
  public app: AppStore
  public user: UserStore
  public video: VideoStore
  public dataOverview: DataOverviewStore

  constructor(context: RootContext) {
    this.context = context
    this.app = new AppStore(this.context)
    this.user = new UserStore(this.context)
    this.video = new VideoStore(this.context)
    this.dataOverview = new DataOverviewStore(this.context)
    makeAutoObservable(this)
  }
}
