import {FC} from "react";

interface IHeatMapBlock {
  color?: string
}

export const HeatMapBlock: FC<IHeatMapBlock> = ({color}) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M9.35993 2.64007L12 0L6.13094e-07 0L0 12L2.64003 9.35997H2.63993L2.63993 2.63997L9.35993 2.63997V2.64007Z"
            fill={color}/>
      {/*<path fillRule="evenodd" clipRule="evenodd" d="M8.09999 10.9L7 12L12 12L12 7L10.8999 8.10015H10.9V10.9001H8.09999V10.9Z" fill={color}/>*/}
    </svg>

  )
}