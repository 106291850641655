import { FC, useEffect, useRef, useState } from 'react'
import styles from './DataDetail.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  Skeleton,
  Button,
  Typography,
  Modal,
  Collapse,
  theme,
  Segmented,
  Tooltip,
  message,
  Spin,
} from 'antd'
import AudioPlayer from 'react-h5-audio-player'
import videojs from 'video.js'
import WaveSurfer from 'wavesurfer.js'
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import Minimap from 'wavesurfer.js/dist/plugins/minimap.esm.js'
import { CaretLeftOutlined } from '@ant-design/icons'
import { IVideoMeta } from '@/store/videoStore'
import axios from 'axios'
import { baseUrl } from '@/config'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'
import { Chart } from '@antv/g2'
import unAnnotationIDs from '@/datas/未打标视频_打标ID_340.json'

const DataDetail: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const navigation = useNavigate()
  const location = useLocation()
  const { ID } = useParams()

  const containerRef = useRef(null)
  const videoRef = useRef(null)

  const [ifLoading, setIfLoading] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const [videoMeta, setVideoMeta] = useState<IVideoMeta>()
  const [featuresMeta, setFeaturesMeta] = useState()
  const [segmentedV, setSegmenteV] = useState<'音频' | '视频'>('视频')
  const [ifLoading2, setIfLoading2] = useState<boolean>(false)

  let videoPlayer
  let ws
  const initWS = () => {
    ws = WaveSurfer.create({
      container: containerRef.current,
      waveColor: '#C1C6FF',
      progressColor: c('primary'),
      // Pass the video element in the `media` param
      media: videoRef.current,
      plugins: [
        TimelinePlugin.create(),
        Minimap.create({
          height: 20,
          waveColor: '#ddd',
          progressColor: '#999',
          // the Minimap takes all the same options as the WaveSurfer itself
        }),
      ],
      renderFunction: (channels, ctx) => {
        const { width, height } = ctx.canvas
        const scale = channels[0].length / width
        const step = 4

        ctx.translate(0, height / 2)
        ctx.strokeStyle = ctx.fillStyle
        ctx.beginPath()

        for (let i = 0; i < width; i += step * 2) {
          const index = Math.floor(i * scale)
          const value = Math.abs(channels[0][index])
          let x = i
          let y = value * height

          ctx.moveTo(x, 0)
          ctx.lineTo(x, y)
          ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, true)
          ctx.lineTo(x + step, 0)

          x = x + step
          y = -y
          ctx.moveTo(x, 0)
          ctx.lineTo(x, y)
          ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, false)
          ctx.lineTo(x + step, 0)
        }

        ctx.stroke()
        ctx.closePath()
      },
    })
  }

  const getMovieName = (movieName: string) => {
    return movieName.replace(' - 电影', '')
  }
  const getRegion = (regions: string[]) => {
    let result = ''
    regions.map((item, _index) => (result += `${item} `))
    return result
  }

  const getVideoMeta = async () => {
    await axios
      .post(`${baseUrl}/app/video/video/getDetail`, { ID: ID })
      .then((res) => {
        console.log('getVideoMeta', res.data.data)
        setVideoMeta(res.data.data)
        // videoStore.setVideoList(res.data.data)
        // messageApi.success({ key: 'getData', content: '数据加载完毕' })
        // setIfLoading(false)
        return
      })
      .catch((error) => {
        console.log('error', error)
        // messageApi.error({ key: 'getData', content: '数据加载失败' })
        // setIfLoading(false)
        return
      })
  }

  const getFeaturesMeta = async () => {
    /*
      关于视频ID命名的问题：最开始是有1800+个原始视频片段（命名为xxxx_0yy），也就是网页主页展示的视频内容，他们时长是各异的；
      在后面的打标工作里，由于需要统一时长（30s），对原视频进行了裁切，最终得到1500个切片（命名为xxxx_3yy），另有300个视频无法切到30s，未被纳入打标范围之内
    */
    const temp = ID.split('')
    temp[temp.length - 3] = '3'
    const postID = temp.join('')
    console.log(`视频片段ID:${ID},打标片段ID:${postID}`)
    // console.log(unAnnotationIDs)
    if (unAnnotationIDs.includes(postID)) {
      console.log('该片段未纳入打标范围')
      return
    }
    await axios
      .post(`${baseUrl}/app/video/video/getFeatures`, { ID: postID })
      .then((res) => {
        console.log('getFeaturesMeta', res.data.data)
        // setVideoMeta(res.data.data)
        setFeaturesMeta(res.data.data)
        // messageApi.success({ key: 'getData', content: '数据加载完毕' })
        // setIfLoading(false)
        return
      })
      .catch((error) => {
        console.log('error', error)
        // messageApi.error({ key: 'getData', content: '数据加载失败' })
        // setIfLoading(false)
        return
      })
  }

  useEffect(() => {
    getVideoMeta()
    getFeaturesMeta()
  }, [])

  useEffect(() => {
    if (videoMeta) {
      videoPlayer = videojs(videoRef.current)

      videoPlayer.on('loadedmetadata', () => {
        console.log('video loaded')
        initWS()
        ws.on('redrawcomplete', () => {
          setIfLoading(false)
        })
      })

      videoPlayer.src(videoMeta.videoUrl)
    }

    return () => {
      if (videoMeta) {
        videoPlayer.dispose()
        ws && ws.destroy()
      }
    }
  }, [videoMeta])

  useEffect(() => {
    setIfLoading2(true)
    setTimeout(() => setIfLoading2(false), 500)
  }, [segmentedV])

  return (
    <>
      <div {...styleClass(['layout'], { background: colorBgContainer })}>
        <div {...styleClass(['header'])}>
          <Button
            style={{ width: 32, height: 32, padding: 0 }}
            onClick={() => {
              if (location.state && location.state.jumped) {
                navigation(-1)
              } else {
                navigation('/data_browser')
              }
            }}
          >
            <CaretLeftOutlined rev={null} />
          </Button>
          <div {...styleClass(['header-text'])}>{'资源详情'}</div>
        </div>
        <div {...styleClass(['content'])}>
          <div {...styleClass(['media'])}>
            <Collapse
              items={[
                {
                  key: '1',
                  label: (
                    <div {...styleClass(['collapse-text'])}>
                      {videoMeta &&
                        `电影:${getMovieName(
                          videoMeta.movieName
                        )} --- 片段序号:${videoMeta.index}`}
                    </div>
                  ),
                  children: (
                    <div {...styleClass(['info-card-content'])}>
                      <div {...styleClass(['info-card-content-block'])}>
                        <div {...styleClass(['info-card-content-title'])}>
                          {`类型: `}
                        </div>
                        <div {...styleClass(['info-card-content-tags'])}>
                          {videoMeta &&
                            videoMeta.tags.map((item, index) => (
                              <Typography.Text
                                key={`${item}-${index}`}
                                code
                                {...styleClass(['info-card-content-tags-text'])}
                              >
                                {item}
                              </Typography.Text>
                            ))}
                        </div>
                      </div>
                      <div {...styleClass(['info-card-content-block'])}>
                        <div {...styleClass(['info-card-content-title'])}>
                          {`国家: `}
                        </div>
                        <div {...styleClass(['info-card-content-text'])}>
                          {videoMeta && getRegion(videoMeta.regions)}
                        </div>
                      </div>
                      <div {...styleClass(['info-card-content-block'])}>
                        <div {...styleClass(['info-card-content-title'])}>
                          {`评分: `}
                        </div>
                        <Typography.Text
                          mark
                          {...styleClass(['info-card-content-text'])}
                        >
                          {videoMeta && `${videoMeta.score}`}
                        </Typography.Text>
                      </div>
                      <div {...styleClass(['info-card-content-block'])}>
                        <div {...styleClass(['info-card-content-title'])}>
                          {`上映日期: `}
                        </div>
                        <Typography.Text
                          keyboard
                          {...styleClass(['info-card-content-text'])}
                        >
                          {videoMeta && `${videoMeta.release_date}`}
                        </Typography.Text>
                      </div>
                      <div {...styleClass(['info-card-content-block'])}>
                        <div {...styleClass(['info-card-content-title'])}>
                          {`导演: `}
                        </div>
                        <div {...styleClass(['info-card-content-text'])}>
                          {videoMeta && `${videoMeta.directors}`}
                        </div>
                      </div>
                      <div {...styleClass(['info-card-content-block'])}>
                        <div {...styleClass(['info-card-content-title'])}>
                          {`描述: `}
                        </div>
                        <div {...styleClass(['info-card-content-text'])}>
                          {videoMeta && `${videoMeta.storyline}`}
                        </div>
                      </div>
                    </div>
                  ),
                },
              ]}
            ></Collapse>
            <div {...styleClass(['media-video'])}>
              <video
                ref={videoRef}
                {...styleClass([], {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  boxShadow: '0 0 3px 0 rgba(255, 255, 255, 0.35)',
                  borderRadius: 6,
                  overflow: 'hidden',
                })}
                className="video-js"
                controls
                preload={'auto'}
                // poster={videoMeta.coverUrl}
              >
                <source
                  type="video/mp4"
                  src={videoMeta && videoMeta.videoUrl}
                ></source>
              </video>
            </div>

            <div {...styleClass(['media-audio-wrapper'])}>
              <div {...styleClass(['media-audio'])} ref={containerRef}></div>
              {ifLoading && (
                <Skeleton active {...styleClass(['media-audio-spin'])} />
              )}
            </div>
            <Button
              type={'default'}
              {...styleClass(['media-drawer-trigger'])}
              onClick={() => setOpen(true)}
            >
              原始音/视频
            </Button>
          </div>

          <div {...styleClass(['data-sider'])}>
            <div {...styleClass(['data-sider-header'])}>
              <div {...styleClass(['data-sider-header-title'])}>
                {'视听特征'}
              </div>
              <Segmented
                options={['视频', '音频']}
                value={segmentedV}
                onChange={(value) => setSegmenteV(value as '视频' | '音频')}
              />
            </div>
            {!ifLoading2 ? (
              <>
                {!featuresMeta && (
                  <div {...styleClass(['data-sider-divider'])}>
                    <div {...styleClass(['data-sider-divider-line'])}></div>
                    <div
                      {...styleClass(['data-sider-divider-text'])}
                    >{`该片段未纳入打标范围`}</div>
                    <div {...styleClass(['data-sider-divider-line'])}></div>
                  </div>
                )}

                {featuresMeta && (
                  <div {...styleClass(['data-sider-divider'])}>
                    <div {...styleClass(['data-sider-divider-line'])}></div>
                    <div
                      {...styleClass(['data-sider-divider-text'])}
                    >{`情感VA`}</div>
                    <div {...styleClass(['data-sider-divider-line'])}></div>
                  </div>
                )}

                {featuresMeta && (
                  <BarChart
                    arousal={
                      featuresMeta['perceptionData']['emotionFeatures'][
                        segmentedV === '视频' ? 2 : 0
                      ]['averageScore'] / 100
                    }
                    valence={
                      featuresMeta['perceptionData']['emotionFeatures'][
                        segmentedV === '视频' ? 3 : 1
                      ]['averageScore'] / 100
                    }
                    mixedArousal={
                      featuresMeta['perceptionData']['emotionFeatures'][4][
                        'averageScore'
                      ] / 100
                    }
                    mixedValence={
                      featuresMeta['perceptionData']['emotionFeatures'][5][
                        'averageScore'
                      ] / 100
                    }
                  />
                )}

                {featuresMeta && (
                  <div {...styleClass(['data-sider-divider'])}>
                    <div {...styleClass(['data-sider-divider-line'])}></div>
                    <div
                      {...styleClass(['data-sider-divider-text'])}
                    >{`感知特征【${segmentedV}】`}</div>
                    <div {...styleClass(['data-sider-divider-line'])}></div>
                  </div>
                )}

                {featuresMeta && (
                  <DistributionChart
                    mode={segmentedV}
                    featuresMeta={featuresMeta}
                  />
                )}

                {featuresMeta && (
                  <div {...styleClass(['data-sider-divider'])}>
                    <div {...styleClass(['data-sider-divider-line'])}></div>
                    <div
                      {...styleClass(['data-sider-divider-text'])}
                    >{`计算特征【${segmentedV}】`}</div>
                    <div {...styleClass(['data-sider-divider-line'])}></div>
                  </div>
                )}

                {featuresMeta && (
                  <RadarChart
                    data={
                      featuresMeta['calcData'][
                        segmentedV === '视频'
                          ? 'calcVideoFeatures'
                          : 'calcAudioFeatures'
                      ]
                    }
                  />
                )}
              </>
            ) : (
              <Spin size={'large'} />
            )}
          </div>
        </div>
      </div>

      <Modal
        width={780}
        title="原始音/视频"
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        open={open}
        footer={[
          <Button
            key={'confirm'}
            type={'primary'}
            onClick={() => setOpen(false)}
          >
            确认
          </Button>,
        ]}
      >
        <div {...styleClass(['drawer-layout'])}>
          <video
            // ref={videoRef}
            {...styleClass([], {
              width: 720,
              height: 405,
              objectFit: 'contain',
              boxShadow: '0 0 3px 0 rgba(255, 255, 255, 0.35)',
              borderRadius: 6,
              overflow: 'hidden',
            })}
            className="video-js"
            controls
            preload={'auto'}
            // poster={videoMeta.coverUrl}
          >
            <source
              type="video/mp4"
              src={videoMeta && videoMeta.originalVideoUrl}
            ></source>
          </video>
          <AudioPlayer
            style={{ width: 720 }}
            autoPlay={false} // 禁止自动播放
            hasDefaultKeyBindings={false} // 取消键盘绑定
            showFilledVolume={true}
            src={videoMeta && videoMeta.originalAudioUrl}
          />
        </div>
      </Modal>
    </>
  )
}

export default DataDetail

interface IDistributionChart {
  mode: '视频' | '音频'
  featuresMeta?: any
}

const DistributionChart: FC<IDistributionChart> = ({ mode, featuresMeta }) => {
  const styleClass = useStyles(styles)
  const [scores, setScores] = useState([])

  useEffect(() => {
    // 视频有6个感知特征需要展示，音频只有3个
    const feature_num = mode === '视频' ? 6 : 5
    const features =
      featuresMeta['perceptionData'][
        mode === '视频' ? 'videoFeatures' : 'audioFeatures'
      ]

    function generateNestedArrayWithSum() {
      const nestedArray = []
      for (let i = 0; i < feature_num; i++) {
        const scoreRange = features[i]['range'] as [number, number]
        const innerArray = Array(7).fill(0) // 初始化一个长度为 7 的元素为 0 的数组
        // scoreRange 分成7份
        // count表示该特征有几个人打标
        for (let j = 0; j < features[i]['count']; j++) {
          const level = Math.floor(
            (features[i]['scores'][j] - scoreRange[0]) /
              ((scoreRange[1] - scoreRange[0]) / 7)
          )
          innerArray[level]++
        }
        nestedArray.push(innerArray)
      }
      return nestedArray
    }
    setScores(generateNestedArrayWithSum)
  }, [mode, featuresMeta])

  const videoFeatures = [
    { title: '叙事节奏', subtitle: '叙事紧迫感' },
    { title: '叙事内容', subtitle: '叙事高潮感' },
    { title: '形象亲缘', subtitle: '形象亲和感' },
    { title: '人物张力', subtitle: '人物印象感' },
    { title: '场景张力', subtitle: '场景印象感' },
    { title: '动作能量', subtitle: '动作能量感' },
  ]
  const audioFeatures = [
    { title: '旋律起伏', subtitle: '音符高低' },
    { title: '和声和谐', subtitle: '协调舒适' },
    { title: '和声丰富', subtitle: '和弦配器' },
    { title: '节奏律动', subtitle: '动感律动' },
    { title: '音色冷暖', subtitle: '温暖冷峻' },
    { title: '123', subtitle: '123' }, // 补丁，避免切换音频时，scores未及时更新导致数组audioFeatures[index]中的index到6溢出
  ]
  return (
    <div {...styleClass(['distribution-layout'])}>
      {scores.map((score, index) => (
        <DistributionChartItem
          key={
            mode === '视频'
              ? videoFeatures[index]['title']
              : audioFeatures[index]['title']
          }
          title={
            mode === '视频'
              ? videoFeatures[index]['title']
              : audioFeatures[index]['title']
          }
          subtitle={
            mode === '视频'
              ? videoFeatures[index]['subtitle']
              : audioFeatures[index]['subtitle']
          }
          score={score}
        />
      ))}
    </div>
  )
}

interface IDistributionChartItem {
  title?: string
  subtitle?: string
  score?: number[]
}

const DistributionChartItem: FC<IDistributionChartItem> = ({
  title,
  subtitle,
  score,
}) => {
  const styleClass = useStyles(styles)

  return (
    <div {...styleClass(['distribution-item'])}>
      <div {...styleClass(['distribution-item-left'])}>
        <div {...styleClass(['distribution-item-left-title'])}>{title}</div>
        <div {...styleClass(['distribution-item-left-subtitle'])}>
          {subtitle}
        </div>
      </div>

      <div {...styleClass(['distribution-item-right'])}>
        {score.map((freq, index) => {
          if (freq !== 0) {
            return (
              <Tooltip
                key={`${title}-${index}`}
                title={`等级${index + 1} - ${freq}人`}
              >
                <motion.div
                  {...styleClass(['distribution-item-right-block'], {
                    cursor: 'pointer',
                  })}
                  initial={{ background: 'rgba(255,255,255,0.05)' }}
                  whileHover={{ background: 'rgba(255,255,255,0.2)' }}
                  transition={{ ...motionEasing }}
                >
                  <div
                    {...styleClass(['distribution-item-right-block-inner'], {
                      width: `${(freq / 7) * 120}%`,
                      height: `${(freq / 7) * 120}%`,
                    })}
                  ></div>
                </motion.div>
              </Tooltip>
            )
          } else {
            return (
              <Tooltip
                key={`${title}-${index}`}
                title={`${index + 1}分 - ${freq}人`}
              >
                <motion.div
                  {...styleClass(['distribution-item-right-block'], {
                    cursor: 'pointer',
                  })}
                  initial={{ background: 'rgba(255,255,255,0)' }}
                  whileHover={{ background: 'rgba(255,255,255,0.05)' }}
                  transition={{ ...motionEasing }}
                >
                  <div
                    {...styleClass(['distribution-item-right-block-inner'], {
                      width: `${(freq / 7) * 120}%`,
                      height: `${(freq / 7) * 120}%`,
                    })}
                  ></div>
                </motion.div>
              </Tooltip>
            )
          }
        })}
      </div>
    </div>
  )
}

interface IBarChart {
  arousal?: number
  valence?: number
  mixedArousal?: number
  mixedValence?: number
}

const BarChart: FC<IBarChart> = ({
  arousal = 0.45,
  valence = -0.6,
  mixedArousal = 0.3,
  mixedValence = 0.45,
}) => {
  const styleClass = useStyles(styles)
  return (
    <div {...styleClass(['barchart'])}>
      <div {...styleClass(['barchart-item'])}>
        <div {...styleClass(['barchart-left'])}>
          <div {...styleClass(['barchart-left-title'])}>{'唤醒度A'}</div>
          <div {...styleClass(['barchart-left-subtitle'])}>
            {'情感的强烈程度'}
          </div>
        </div>
        <div {...styleClass(['barchart-right'])}>
          <div
            {...styleClass(['barchart-right-bar'], {
              background: '#B0B7FE',
              top: 0,
              left: arousal >= 0 ? '50%' : `${50 - Math.abs(arousal) * 50}%`,
              width: `${Math.abs(arousal) * 50}%`,
            })}
          ></div>
          <div
            {...styleClass(['barchart-right-bar'], {
              background: '#E7D4A5',
              top: '50%',
              left:
                mixedArousal >= 0
                  ? '50%'
                  : `${50 - Math.abs(mixedArousal) * 50}%`,
              width: `${Math.abs(mixedArousal) * 50}%`,
            })}
          ></div>
        </div>
      </div>

      <div {...styleClass(['barchart-item'])}>
        <div {...styleClass(['barchart-left'])}>
          <div {...styleClass(['barchart-left-title'])}>{'效价V'}</div>
          <div {...styleClass(['barchart-left-subtitle'])}>
            {'情感的价值取向'}
          </div>
        </div>

        <div {...styleClass(['barchart-right'])}>
          <div
            {...styleClass(['barchart-right-bar'], {
              background: '#B0B7FE',
              top: 0,
              left: valence >= 0 ? '50%' : `${50 - Math.abs(valence) * 50}%`,
              width: `${Math.abs(valence) * 50}%`,
            })}
          ></div>
          <div
            {...styleClass(['barchart-right-bar'], {
              background: '#E7D4A5',
              top: '50%',
              left:
                mixedValence >= 0
                  ? '50%'
                  : `${50 - Math.abs(mixedValence) * 50}%`,
              width: `${Math.abs(mixedValence) * 50}%`,
            })}
          ></div>
        </div>
      </div>

      <div {...styleClass(['barchart-legend'])}>
        <div {...styleClass(['barchart-legend-item'])}>
          <img
            src={'https://s3.bmp.ovh/imgs/2024/11/30/88c7b204c0f64f76.png'}
            width={10}
          />
          <div {...styleClass(['barchart-legend-item-text'])}>
            {'音视频独立效果'}
          </div>
        </div>
        <div {...styleClass(['barchart-legend-item'])}>
          <img
            src={'https://s3.bmp.ovh/imgs/2024/11/30/39119c678ef596aa.png'}
            width={10}
          />
          <div {...styleClass(['barchart-legend-item-text'])}>
            {'音视频融合效果'}
          </div>
        </div>
      </div>
    </div>
  )
}

interface IRadarChart {
  data?: any // calcData
}

export const RadarChart: FC<IRadarChart> = ({ data }) => {
  const styleClass = useStyles(styles)
  const containerRef = useRef()

  const dataTransform = (
    back_end_data: Array<{
      feature: string
      score: number
      averageScore: number
    }>
  ) => {
    const result = []
    for (let i = 0; i < back_end_data.length; i++) {
      result.push({
        item: back_end_data[i].feature,
        type: '片段实例',
        score: back_end_data[i].score,
      })
      result.push({
        item: back_end_data[i].feature,
        type: '样本均值',
        score: back_end_data[i].averageScore,
      })
    }
    return result
  }

  const findMaxScore = (
    back_end_data: Array<{
      feature: string
      score: number
      averageScore: number
    }>
  ) => {
    let domainMax = 20
    back_end_data.map((item, index) => {
      if (item.score > domainMax || item.averageScore > domainMax) {
        domainMax =
          item.score > item.averageScore
            ? item.score + 10
            : item.averageScore + 10
      }
    })
    return domainMax
  }

  useEffect(() => {
    const container = containerRef.current as HTMLElement
    container.innerHTML = ''

    const chart = new Chart({
      container: container,
      autoFit: true,
      theme: 'classicDark',
    })

    chart.coordinate({ type: 'polar' })

    chart
      .data(dataTransform(data))
      .scale('x', { padding: 0.5, align: 0 })
      .scale('y', { tickCount: 5, domainMin: 0, domainMax: findMaxScore(data) })
      .axis('x', {
        grid: true,
        gridLineWidth: 1,
        tick: false,
        gridLineDash: [0, 0],
      })
      .axis('y', {
        zIndex: 1,
        title: false,
        gridConnect: 'line',
        gridLineWidth: 1,
        gridLineDash: [0, 0],
      })

    chart
      .area()
      .encode('x', 'item')
      .encode('y', 'score')
      .encode('color', 'type')
      .style('fillOpacity', 0.5)

    chart
      .line()
      .encode('x', 'item')
      .encode('y', 'score')
      .encode('color', 'type')
      .style('lineWidth', 2)

    chart
      .point()
      .encode('x', 'item')
      .encode('y', 'score')
      .encode('color', 'type')
      .encode('shape', 'point')
      .encode('size', 3)
      .tooltip(null)

    chart.interaction('tooltip', { crosshairsLineDash: [4, 4] })

    chart.render()
  }, [data])
  return <div ref={containerRef} {...styleClass(['radar-layout'])}></div>
}
