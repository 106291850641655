import React, { Key, ReactNode, useEffect, useState } from 'react'
import { useStyles } from '@/hooks/styles'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { SvgIcon } from '@/components/icons'
import styles from './Home.module.scss'
import {
  AppstoreAddOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  ForkOutlined,
  HeatMapOutlined,
} from '@ant-design/icons'
import { Layout, Menu, MenuProps, theme } from 'antd'
import { MusicVideo } from '@/components/icons/MusicVideo'

const { Header, Content, Footer, Sider } = Layout
type MenuItem = Required<MenuProps>['items'][number]

const getItem = (
  label: ReactNode,
  key: Key,
  icon?: ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

const items: MenuProps['items'] = [
  getItem('视听资源', '1', <DatabaseOutlined rev={null} />, [
    getItem('数据资产库', '1-1', null),
    getItem('资源概况', '1-2', null),
  ]),
  getItem('特征挖掘', '2', <ForkOutlined rev={null} />),
  getItem('情感化音乐推荐', '3', <HeatMapOutlined rev={null} />),
  getItem('个性化音乐检索', '4', <AppstoreAddOutlined rev={null} />),
  getItem('智能影视配乐', '5', <AppstoreAddOutlined rev={null} />),
  { type: 'divider' },
  getItem('数据标注', '6', <ContainerOutlined rev={null} />),
  { type: 'divider' },
  getItem('#多模态关联计算方法', '7'),
  getItem('#面向影视场景的音乐生成模型', '8'),
  getItem('#受众的情感计算与预测模型研究', '9'),
]

const Home = () => {
  const styleClass = useStyles(styles)
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const navigation = useNavigate()
  const location = useLocation()
  const [selectedKeys, setSelectedKeys] = useState(['1-1'])
  const menu_key_router = [
    { key: '1-1', router: '/data_browser' },
    { key: '1-2', router: '/data_overview' },
    { key: '6', router: '/mark' },
    { key: '7', router: '/feature_extract' },
    { key: '8', router: '/music_gen' },
    { key: '9', router: '/affective_computing' },
  ]
  const handleMenuItem = (key) => {
    for (let i = 0; i < menu_key_router.length; i++) {
      if (menu_key_router[i].key == key) {
        console.log(menu_key_router[i].router)
        navigation(menu_key_router[i].router)
        break
      }
    }
  }

  useEffect(() => {
    for (let i = 0; i < menu_key_router.length; i++) {
      if (menu_key_router[i].router === location.pathname) {
        if (menu_key_router[i].key !== selectedKeys[0]) {
          setSelectedKeys([menu_key_router[i].key])
          break
        }
      }
    }
  }, [])

  return (
    <Layout {...styleClass([], { minHeight: '100vh' })}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          // console.log(broken)
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type)
        }}
      >
        <div {...styleClass(['logo'])}>
          <SvgIcon icon={MusicVideo} />
          <div {...styleClass(['logo-text'])}>Music-Video</div>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={selectedKeys}
          defaultOpenKeys={['1']}
          defaultSelectedKeys={['1-1']}
          items={items}
          onClick={(itemMeta) => {
            setSelectedKeys([itemMeta.key])
            handleMenuItem(itemMeta.key)
          }}
        />
      </Sider>
      <Layout>
        {/* <Header style={{ padding: 0, background: colorBgContainer }} /> */}
        <Content style={{ margin: '16px 16px 0' }}>
          <div
            style={{
              // padding: 16,
              minWidth: 760,
              minHeight: 400,
              // background: colorBgContainer,
              borderRadius: 6,
            }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', padding: '16px 50px' }}>
          inlab © copyright
        </Footer>
      </Layout>
    </Layout>
  )
}

export default Home
