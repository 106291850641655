import { RootContext } from '@/App.context'
import { makeAutoObservable } from 'mobx'
import data from '@/components/vis/AnnotationData.json'

export type IAnnotationData = {
  video_id: string
  species: string // user_id
  x: string // 特征名
  y: number // 打标分数
}

export type IFeatureInfo = {
  name: string // 特征名称
  type: 'video' | 'audio'
  accuracyLevel: number // 准确率水平 1 2 3 4 5
  from: '计算' | '标注' // 特征建模 | 数据建模
  time: boolean // 是否为时序数据
  score_range_distribution?: number[] // 样本在该特征上的分数分布（0-100分等分为20个区间，每个元素表示该区间的样本数）
}

export default class DataOverviewStore {
  public context: RootContext

  public hoveredFeature = []
  public annotationData = data
  public selectedFeature = ['人物张力', '音色冷暖']
  public featureInfo: IFeatureInfo[] = [
    {
      name: '叙事节奏',
      type: 'video',
      accuracyLevel: 1,
      from: '标注',
      time: false,
      score_range_distribution: [
        0, 15, 22, 79, 158, 246, 252, 169, 135, 86, 78, 78, 62, 38, 35, 26, 28,
        16, 3, 0,
      ],
    },
    {
      name: '叙事内容',
      type: 'video',
      accuracyLevel: 3,
      from: '标注',
      time: true, // TODO: 修改为false
      score_range_distribution: [
        0, 1, 12, 20, 63, 122, 231, 292, 213, 144, 98, 86, 62, 59, 46, 42, 22,
        9, 4, 0,
      ],
    },
    {
      name: '形象亲缘',
      type: 'video',
      accuracyLevel: 3,
      from: '标注',
      time: false,
      score_range_distribution: [
        5, 19, 42, 76, 95, 124, 158, 204, 168, 96, 75, 104, 99, 113, 55, 39, 35,
        13, 6, 0,
      ],
    },
    // {
    //   name: '人物张力',
    //   type: 'video',
    //   accuracyLevel: 4,
    //   from: '标注',
    //   time: false,
    //   score_range_distribution: [
    //     13, 3, 5, 9, 9, 12, 16, 32, 61, 104, 158, 293, 381, 260, 107, 41, 18, 3,
    //     1, 0,
    //   ],
    // },
    // {
    //   name: '场景张力',
    //   type: 'video',
    //   accuracyLevel: 5,
    //   from: '标注',
    //   time: false,
    //   score_range_distribution: [
    //     0, 0, 1, 6, 20, 149, 328, 276, 155, 161, 116, 92, 76, 57, 42, 27, 18, 2,
    //     0, 0,
    //   ],
    // },
    {
      name: '动作能量',
      type: 'video',
      accuracyLevel: 4,
      from: '标注',
      time: false,
      score_range_distribution: [
        1, 8, 21, 34, 34, 54, 72, 109, 134, 158, 196, 190, 137, 108, 92, 85, 46,
        31, 14, 2,
      ],
    },
    {
      name: '动作幅度',
      type: 'video',
      accuracyLevel: 4,
      from: '计算',
      time: true,
      score_range_distribution: [
        4, 83, 202, 253, 272, 217, 174, 152, 88, 56, 43, 32, 22, 11, 7, 3, 1, 0,
        0, 0,
      ],
    },
    // {
    //   name: '色彩冷暖',
    //   type: 'video',
    //   accuracyLevel: 3,
    //   from: '计算',
    //   time: true,
    //   score_range_distribution: [
    //     10, 20, 29, 77, 106, 158, 165, 161, 145, 135, 102, 168, 77, 68, 55, 49,
    //     37, 29, 19, 10,
    //   ],
    // },
    {
      name: '色彩轻重',
      type: 'video',
      accuracyLevel: 3,
      from: '计算',
      time: true,
      score_range_distribution: [
        63, 146, 151, 135, 127, 132, 128, 126, 123, 118, 100, 101, 69, 48, 32,
        14, 5, 2, 0, 0,
      ],
    },
    // {
    //   name: '旋律走向',
    //   type: 'audio',
    //   accuracyLevel: 4,
    //   from: '计算',
    //   time: true,
    //   score_range_distribution: [
    //     0, 0, 1, 1, 0, 0, 0, 3, 16, 554, 984, 239, 47, 13, 5, 2, 1, 0, 0, 0,
    //   ],
    // },
    {
      name: '旋律起伏',
      type: 'audio',
      accuracyLevel: 4,
      from: '标注',
      time: true,
      score_range_distribution: [
        0, 1, 12, 29, 43, 78, 108, 129, 155, 170, 212, 180, 151, 114, 78, 44,
        21, 1, 0, 0,
      ],
    },
    {
      name: '和声和谐',
      type: 'audio',
      accuracyLevel: 4,
      from: '标注',
      time: false,
      score_range_distribution: [
        0, 0, 2, 1, 7, 21, 46, 129, 233, 214, 195, 186, 272, 157, 57, 5, 1, 0,
        0, 0,
      ],
    },
    {
      name: '和声丰富',
      type: 'audio',
      accuracyLevel: 2,
      from: '标注',
      time: false,
      score_range_distribution: [
        0, 1, 17, 39, 67, 121, 167, 156, 192, 176, 148, 135, 102, 91, 60, 44, 8,
        2, 0, 0,
      ],
    },
    {
      name: '节奏速度',
      type: 'audio',
      accuracyLevel: 4,
      from: '计算',
      time: true,
      score_range_distribution: [
        0, 0, 0, 0, 4, 9, 14, 31, 32, 58, 95, 100, 167, 127, 137, 135, 185, 310,
        440, 17,
      ],
    },
    // {
    //   name: '节奏律动',
    //   type: 'audio',
    //   accuracyLevel: 3,
    //   from: '标注',
    //   time: false,
    //   score_range_distribution: [
    //     2, 11, 23, 55, 80, 143, 160, 166, 136, 141, 129, 108, 105, 103, 93, 46,
    //     25, 0, 0, 0,
    //   ],
    // },
    {
      name: '音色明暗',
      type: 'audio',
      accuracyLevel: 5,
      from: '计算',
      time: false,
      score_range_distribution: [
        58, 171, 347, 378, 316, 251, 118, 93, 49, 32, 19, 15, 6, 1, 5, 2, 2, 1,
        1, 0,
      ],
    },
    {
      name: '音色冷暖',
      type: 'audio',
      accuracyLevel: 3,
      from: '标注',
      time: false,
      score_range_distribution: [
        0, 0, 0, 0, 1, 18, 52, 139, 222, 232, 244, 223, 196, 156, 36, 7, 0, 0,
        0, 0,
      ],
    },
  ]

  constructor(context: RootContext) {
    this.context = context
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setHoveredFeature(featureName: string, event: 'in' | 'out') {
    if (event === 'in') {
      this.hoveredFeature.push(featureName)
    } else {
      this.hoveredFeature.splice(this.hoveredFeature.indexOf(featureName), 1)
    }

    this.hoveredFeature = [...this.hoveredFeature]
  }

  setSelectedFeature(featureName: string) {
    if (this.selectedFeature.indexOf(featureName) < 0) {
      this.selectedFeature.push(featureName)
    } else {
      this.selectedFeature.splice(this.selectedFeature.indexOf(featureName), 1)
    }
    this.selectedFeature = [...this.selectedFeature]
  }
}
