import { RouteObject, useNavigate } from 'react-router-dom'
import { FC, lazy, ReactNode, Suspense } from 'react'

import Home from '@/pages/home/Home'
import HomePage from '@/pages/homepage/HomePage'
import DataBrowser from '@/pages/data/DataBrowser'
import DataDetail from '@/pages/data/DataDetail'
import DataOverview from '@/pages/data/DataOverview'
import Mark from '@/pages/mark/Mark'
import FeatureExtract from '@/pages/extract/FeatureExtract'
import MusicGen from '@/pages/musicgen/MusicGen'
import AffectiveComputing from '@/pages/affectivecomputing/AffectiveComputing'
// const Graph = lazy(() => import('@/pages/graph/Graph'))

const lazyLoad = (children: ReactNode): ReactNode => {
  return <Suspense fallback={<div>loading</div>}>{children}</Suspense>
}

export const router: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
    children: [
      { path: '/data_browser', element: lazyLoad(<DataBrowser />) },
      { path: '/data_detail/:ID', element: lazyLoad(<DataDetail />) },
      { path: '/data_overview', element: lazyLoad(<DataOverview />) },
      { path: '/feature_extract', element: lazyLoad(<FeatureExtract />) }, // 软件测试报告专用-2.2多模态计算关联方法
      { path: '/music_gen', element: lazyLoad(<MusicGen />) }, // 软件测试报告专用-4.4面向影视场景的音乐生成模型
      {
        path: '/affective_computing',
        element: lazyLoad(<AffectiveComputing />),
      }, // 软件测试报告专用-5.2受众的情感计算与预测模型研究
      { path: '/mark', element: lazyLoad(<Mark />) },
    ],
  },

  /* {
    path: '',
    element: <HomePage />,
  }, */
  /* {
    path: '/login',
    element: <Login />,
  }, */
]
