import { CSSProperties, FC, useEffect, useRef, useState } from 'react'
import { useColorVar, useStyles } from '@/hooks/styles'
import styles from './FeatureExtract.module.scss'
import { Button, theme, Typography, Upload } from 'antd'
import React from 'react'
import { Chart } from '@antv/g2'
import axios from 'axios'
import { token, uploadUrl } from '@/config'
import { AudioPlayer } from 'react-audio-play'

type vis_data = Array<{ source: string; target: 'a' | 'v'; value: number }>
const zero_video_features = [
  { feature: '叙事节奏', value: 0, type: 'video' },
  { feature: '叙事内容', value: 0, type: 'video' },
  { feature: '形象亲缘', value: 0, type: 'video' },
  { feature: '人物张力', value: 0, type: 'video' },
  { feature: '场景张力', value: 0, type: 'video' },
  { feature: '动作能量', value: 0, type: 'video' },
]
const zero_audio_features = [
  { feature: '和声丰富', value: 0, type: 'audio' },
  { feature: '旋律起伏', value: 0, type: 'audio' },
  { feature: '节奏律动', value: 0, type: 'audio' },
  { feature: '音色冷暖', value: 0, type: 'audio' },
  { feature: '和声和谐', value: 0, type: 'audio' },
]

const FeatureExtract: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const videoRef = useRef(null)
  const [videoUrl, setVideoUrl] = useState('')
  const [audioUrl, setAudioUrl] = useState('')

  const [videoFeatures, setVideoFeatures] = useState([
    { feature: '叙事节奏', value: 0, type: 'video' },
    { feature: '叙事内容', value: 0, type: 'video' },
    { feature: '形象亲缘', value: 0, type: 'video' },
    { feature: '人物张力', value: 0, type: 'video' },
    { feature: '场景张力', value: 0, type: 'video' },
    { feature: '动作能量', value: 0, type: 'video' },
  ])
  const [audioFeatures, setAudioFeatures] = useState(zero_audio_features)

  const getFeatures = async (type: 'audio' | 'video') => {
    await axios
      .post('https://inlab-server.littleor.cn/perception/api/predict', {
        type: type,
        url: type === 'video' ? videoUrl : audioUrl,
      })
      .then((res) => {
        console.log(res)
        if (type === 'video') {
          setVideoFeatures(
            res.data.features.filter((item) =>
              zero_video_features
                .map((item) => item.feature)
                .includes(item.feature)
            )
          )
        } else {
          setAudioFeatures(
            res.data.features.filter((item) =>
              zero_audio_features
                .map((item) => item.feature)
                .includes(item.feature)
            )
          )
        }
        return
      })
      .catch((error) => {
        console.log('error', error)
        return
      })
  }

  useEffect(() => {
    const videoPlayer = videoRef.current
    videoPlayer.load()
    if (videoUrl !== '') {
      getFeatures('video')
    } else {
      setVideoFeatures(zero_video_features)
    }
  }, [videoUrl])

  useEffect(() => {
    if (audioUrl !== '') {
      getFeatures('audio')
    } else {
      setAudioFeatures(zero_audio_features)
    }
  }, [audioUrl])

  return (
    <div {...styleClass(['layout'], { background: colorBgContainer })}>
      <div {...styleClass(['title'])}>
        <div {...styleClass(['title-text'])}>{'多模态关联计算'}</div>
      </div>
      <div {...styleClass(['container'])}>
        <div {...styleClass(['upload-layout'])}>
          <Upload
            headers={{ Authorization: token }}
            action={uploadUrl}
            maxCount={1}
            onChange={(file) => {
              if ('response' in file.file) {
                const url = file.file.response.data as string
                console.log('video', url)
                setVideoUrl(url)
              }
              if (file.fileList.length === 0) {
                // 表示移除文件
                setVideoUrl('')
              }
            }}
          >
            <Button>{'上传视频文件'}</Button>
          </Upload>
          <div {...styleClass(['video-container'])}>
            <video
              ref={videoRef}
              style={{ width: '100%', height: '100%' }}
              className="video-js"
              controls
              preload="auto"
              autoPlay
            >
              <source src={videoUrl} type="video/mp4"></source>
            </video>
          </div>

          <Upload
            headers={{ Authorization: token }}
            action={uploadUrl}
            maxCount={1}
            onChange={(file) => {
              if ('response' in file.file) {
                const url = file.file.response.data as string
                console.log('audio', url)
                setAudioUrl(url)
              }
              if (file.fileList.length === 0) {
                // 表示移除文件
                setAudioUrl('')
              }
            }}
          >
            <Button {...styleClass([], { marginTop: 20 })}>
              {'上传音频文件'}
            </Button>
          </Upload>

          <div {...styleClass(['audio-wrapper'])}>
            <AudioPlayer
              src={audioUrl}
              color="#cfcfcf"
              sliderColor="#94b9ff"
              backgroundColor="#282E34"
            />
          </div>
        </div>

        <div {...styleClass(['vis-layout'])}>
          <div {...styleClass(['vis-divider'])}>
            <div {...styleClass(['vis-divider-text'])}>{`视频感知特征`}</div>
            <div {...styleClass(['vis-divider-line'])}></div>
          </div>
          <div {...styleClass(['vis-list-wrapper'])}>
            <div {...styleClass(['vis-list'])}>
              {videoFeatures.map((item, index) => (
                <div key={item.feature} {...styleClass(['vis-line'])}>
                  <div {...styleClass(['vis-line-left'])}>
                    <div {...styleClass(['vis-line-left-text'])}>
                      {item.feature}
                    </div>
                  </div>
                  <div {...styleClass(['vis-line-right'])}>
                    <div
                      {...styleClass(['vis-line-right-bar'], {
                        width: `${item.value * 100}%`,
                      })}
                    ></div>
                  </div>
                </div>
              ))}
            </div>

            <div {...styleClass(['vis-parallel'])}>
              <Parallel />
            </div>
          </div>

          <div {...styleClass(['vis-divider'])}>
            <div {...styleClass(['vis-divider-text'])}>{`音频感知特征`}</div>
            <div {...styleClass(['vis-divider-line'])}></div>
          </div>

          <div {...styleClass(['vis-list-wrapper'])}>
            <div {...styleClass(['vis-list'])}>
              {audioFeatures.map((item, index) => (
                <div key={item.feature} {...styleClass(['vis-line'])}>
                  <div {...styleClass(['vis-line-left'])}>
                    <div {...styleClass(['vis-line-left-text'])}>
                      {item.feature}
                    </div>
                  </div>
                  <div {...styleClass(['vis-line-right'])}>
                    <div
                      {...styleClass(['vis-line-right-bar'], {
                        width: `${item.value * 100}%`,
                      })}
                    ></div>
                  </div>
                </div>
              ))}
            </div>

            <div {...styleClass(['vis-parallel'])}>
              <Parallel />
            </div>
          </div>

          <div
            {...styleClass(['vis-divider'], {
              marginTop: -8,
              marginBottom: 10,
            })}
          >
            <div {...styleClass(['vis-divider-text'])}>{`视听一致性:`}</div>
            <Typography.Text
              mark
              {...styleClass(['vis-divider-text'])}
            >{`25`}</Typography.Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureExtract

// 实则为桑基图
interface IParallel {
  vis_data?: vis_data
}
const Parallel: FC<IParallel> = ({ vis_data }) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const containerRef = useRef()

  const temp = [
    { source: '叙事内容', target: 'a', value: 50 },
    { source: '叙事节奏', target: 'a', value: 20 },
    { source: '形象亲缘', target: 'a', value: 10 },
    { source: '人物张力', target: 'a', value: 50 },
    { source: '叙事内容', target: 'v', value: 30 },
    { source: '叙事节奏', target: 'v', value: 30 },
    { source: '形象亲缘', target: 'v', value: 35 },
    { source: '人物张力', target: 'v', value: 5 },
  ]

  useEffect(() => {
    const container = containerRef.current as HTMLElement
    container.innerHTML = ''
    const chart = new Chart({
      container: container,
      autoFit: true,
      theme: 'classicDark',
    })

    chart
      .sankey()
      .data({
        value: temp,
        transform: [
          {
            type: 'custom',
            callback: (data) => ({ links: data }),
          },
        ],
      })
      .layout({
        nodeAlign: 'center',
        nodePadding: 0.03,
      })
      .scale('color', { palette: 'purples' })
      // .scale('color', { range: schemeTableau10 })
      .style('labelSpacing', 3)
      .style('labelFontWeight', 'bold')
      .style('nodeLineWidth', 1.2)
      .style('linkFillOpacity', 0.4)

    chart.render()
  }, [])

  return <div ref={containerRef} {...styleClass(['parallel'])}></div>
}
