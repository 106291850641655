import { FC, useEffect, useRef } from 'react'
import { useColorVar, useStyles } from '@/hooks/styles'
import styles from '@/components/vis/TreeGraph.module.scss'
import { Graph, treeToGraphData, register, ExtensionCategory } from '@antv/g6'

export const TreeGraph: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const containerRef = useRef()

  const data = {
    id: '-',
    children: [
      {
        id: '视频',
        children: [
          {
            id: '特征建模V',
            children: [
              {
                id: '冷暖感V',
                children: [{ id: '暖区面积占比' }, { id: '温度均值' }],
              },
              {
                id: '轻重感',
                children: [{ id: '亮区面积占比' }, { id: '亮度均值' }],
              },
              {
                id: '幅度',
                children: [{ id: '背景光流均值' }, { id: '主体光流均值' }],
              },
              {
                id: '鲜艳感*',
                children: [{ id: '彩区面积占比*' }, { id: '饱和度均值*' }],
              },
              {
                id: '平衡感*',
                children: [{ id: '偏心距比*' }, { id: '水平对称偏差*' }],
              },
              {
                id: '紧凑感*',
                children: [{ id: '加权质心离散度*' }, { id: '面积紧凑度*' }],
              },
              {
                id: '连贯感*',
                children: [
                  { id: '背景光流信息熵*' },
                  { id: '主体光流信息熵*' },
                ],
              },
            ],
          },
          {
            id: '数据建模V',
            children: [
              { id: '叙事内容' },
              { id: '丰富感V' },
              { id: '亲疏感' },
              { id: '人物张力' },
              { id: '场景张力' },
              { id: '力量感' },
              { id: '情感唤醒V' },
              { id: '情感效价V' },
            ],
          },
          {
            id: '其他底层特征V*',
            children: [
              {
                id: '人脸情绪*',
                children: [
                  { id: '愤怒*' },
                  { id: '厌恶*' },
                  { id: '恐惧*' },
                  { id: '开心*' },
                  { id: '悲伤*' },
                  { id: '惊喜*' },
                  { id: '中立*' },
                ],
              },
              {
                id: '视角*',
                children: [
                  { id: '斜视角*' },
                  { id: '俯视角*' },
                  { id: '仰视角*' },
                  { id: '平视角*' },
                  { id: '鸟瞰视角*' },
                ],
              },
              {
                id: '水平高度*',
                children: [
                  { id: '航拍高度*' },
                  { id: '眼高度*' },
                  { id: '地面高度*' },
                  { id: '胯高度*' },
                  { id: '膝高度*' },
                  { id: '肩高度*' },
                ],
              },
              {
                id: '摄像深度*',
                children: [
                  { id: '近景分值*' },
                  { id: '中景分值*' },
                  { id: '远景分值*' },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '音频',
        children: [
          {
            id: '特征建模A',
            children: [
              {
                id: '走向感',
                children: [
                  { id: '音高变化方向比例' },
                  { id: '音高变化速率均值' },
                ],
              },
              {
                id: '速度感',
                children: [{ id: '节奏均值' }, { id: '节奏峰值比例' }],
              },
              {
                id: '明暗感',
                children: [
                  { id: '高频成分占比' },
                  { id: '频带能量分布均衡性' },
                ],
              },
            ],
          },
          {
            id: '数据建模A',
            children: [
              { id: '起伏感' },
              { id: '和谐感' },
              { id: '丰富感A' },
              { id: '律动感' },
              { id: '冷暖感A' },
              { id: '情感唤醒A' },
              { id: '情感效价A' },
            ],
          },
          {
            id: '其他底层特征A*',
            children: [
              {
                id: '时间域*',
                children: [{ id: '均方根能量*' }, { id: '事件密度*' }],
              },
              {
                id: '频率域*',
                children: [
                  { id: '谱质心*' },
                  { id: '零交叉率*' },
                  { id: '谱滚降*' },
                  { id: '明亮度*' },
                  { id: '谱平坦度*' },
                  { id: '谱不规则性*' },
                  { id: '谱扩展*' },
                  { id: '谱偏度*' },
                  { id: '谱峰度*' },
                  { id: '频谱熵*' },
                  { id: '谱流量*' },
                ],
              },
              {
                id: '音色*',
                children: [
                  { id: '梅尔频率倒谱系数*' },
                  { id: '非和谐度*' },
                  { id: '粗糙度*' },
                ],
              },
              {
                id: '和声*',
                children: [{ id: '色度图*' }, { id: '音调质心*' }],
              },
            ],
          },
        ],
      },
      {
        id: '视听',
        children: [
          {
            id: '数据建模AV',
            children: [{ id: '复合情感唤醒度' }, { id: '复合情感效价' }],
          },
        ],
      },
    ],
  }

  useEffect(() => {
    // register(ExtensionCategory.THEME, 'tree-graph-theme', theme)

    const container = containerRef.current as HTMLElement
    container.innerHTML = ''
    const graph = new Graph({
      container: container,
      autoFit: 'view',
      autoResize: true,
      padding: [50, 30, 40, 30],
      data: treeToGraphData(data),
      node: {
        style: {
          size: (d) => 28 - d.depth * 3,
          labelText: (d) => d.id,
          // labelBackground: true,
          // labelBackgroundFill: '#D9D9D9',
          // labelFill: '#333333',
          // 没有用上的特征，会带*号
          labelFillOpacity: (d) => (d.id.slice(-1) === '*' ? 0.35 : 1),
          labelFontSize: (d) => {
            switch (d.depth) {
              case 0:
                return 48
              case 1:
                return 48
              case 2:
                return 40
              case 3:
                return 36
              case 4:
                return 28
              case 5:
                return 24
            }
          },
          labelFontFamily: 'Gill Sans',
          labelBackgroundRadius: 2,
        },
      },
      edge: {
        type: 'cubic-radial',
        style: {
          lineWidth: (d) => (d.id.slice(-1) === '*' ? 3 : 10),
        },
      },
      layout: {
        type: 'dendrogram',
        radial: true,
        preLayout: false,
      },
      behaviors: [
        'drag-canvas',
        'zoom-canvas',
        {
          key: 'hover-activate',
          type: 'hover-activate',
          degree: 5,
          direction: 'in',
          inactiveState: 'inactive',
        },
      ],
      transforms: ['place-radial-labels'],
      animation: false,
    })

    graph.setTheme('dark')
    graph.render()

    // 在组件卸载时销毁图表实例，释放内存
    return () => graph.destroy()
  }, [])

  return <div ref={containerRef} {...styleClass(['layout'])}></div>
}
