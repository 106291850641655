import { FC, useEffect, useRef } from 'react'
import { useColorVar, useStyles } from '@/hooks/styles'
import styles from '@/components/vis/BulletBar.module.scss'
import { Chart } from '@antv/g2'

export const BulletBar: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const containerRef = useRef()

  useEffect(() => {
    const container = containerRef.current as HTMLElement
    container.innerHTML = ''

    const chart = new Chart({
      container: container,
      autoFit: true,
      padding: 0,
      margin: 0,
      // height: 28,
      theme: 'classicDark',
    })

    const colors = {
      ranges: ['#bfeec8', '#FFe0b0', '#FFbcb8'],
      measures: ['#61DDAA', '#5B8FF9'],
      target: '#39a3f4',
    }

    const data = [
      {
        title: '满意度',
        ranges: 100,
        measures: 60,
        target: 90,
      },
      {
        title: '满意度',
        ranges: 80,
        measures: 10,
      },
      {
        title: '满意度',
        ranges: 30,
      },
    ]

    chart.coordinate({ transform: [{ type: 'transpose' }] })

    chart.data(data).scale('color', {
      range: [colors['ranges'], colors['measures'], colors['target']].flat(),
    })

    chart
      .interval()

      .encode('x', 'title')
      .encode('y', 'ranges')
      .encode('color', (d, i) => ['优', '良', '差'][i])
      .style('maxWidth', 30)

    chart
      .interval()
      .encode('x', 'title')
      .encode('y', 'measures')
      .encode('color', (d, i) => ['下半年', '上半年'][i] || '下半年')
      .style('maxWidth', 20)
      .legend('color', {
        itemMarker: (d) => {
          return d === '目标' ? 'line' : 'square'
        },
        itemMarkerFill: 'rgba(0,0,0,0)',
        itemLabelFontSize: 0,
      })
      .axis('y', {
        title: false,
        grid: false,
        line: false,
        tick: false,
        label: false,
      })
      .axis('x', { line: false, tick: false, label: false })

    chart
      .point()
      .encode('x', 'title')
      .encode('y', 'target')
      .encode('shape', 'line')
      .encode('color', () => '目标')
      .encode('size', 8)
      .style('lineWidth', 1)

    chart.interaction('tooltip', { shared: true })

    chart.render()
  }, [])
  return <div ref={containerRef} {...styleClass(['layout'])}></div>
}
