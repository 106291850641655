import {FC} from "react";

export const MusicVideo: FC = () => {
  return (
    <svg width="34" height="39" viewBox="0 0 34 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.818176 5.21436L8.45454 7.79769V27.3572H0.818176V5.21436Z" fill="white"/>
      <path d="M25.5454 11.8572H33.1818V31.7858L25.5454 29.2024V11.8572Z" fill="white"/>
      <path d="M13.1819 20.7144L20.8182 23.2977V38.4286L13.1819 35.8453V20.7144Z" fill="#BFC4FF"/>
      <path d="M13.1819 0.785645L20.8182 3.36898V18.4999L13.1819 15.9166V0.785645Z" fill="#7782F7"/>
    </svg>
  )
}