import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import styles from './MusicGen.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import {
  theme,
  Tag,
  Input,
  Button,
  Upload,
  Dropdown,
  MenuProps,
  Skeleton,
  message,
} from 'antd'
import OpenAI from 'openai'
import { token, uploadUrl } from '@/config'
import { DownloadOutlined } from '@ant-design/icons'
import videojs from 'video.js'
import WaveSurfer from 'wavesurfer.js'
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import Minimap from 'wavesurfer.js/dist/plugins/minimap.esm.js'

const prompts = `你是一位音乐生成系统的提示词工程师，需要根据用户选择的音乐风格生成适合CLIP模型理解的音乐描述。以下是需要严格遵守的操作指南：

<可选风格>
只接受以下六种预设风格：
['摇滚', '流行', '古典', '民族', '乡村', '电子']
</可选风格>

当收到用户输入时：
1. 首先验证输入是否在预设风格列表中。如果不在，回复："无效风格，请从预设选项中选择"
2. 若为有效风格，根据以下特征对照表生成提示词：
<特征对照表>
摇滚：强节奏吉他、鼓点密集、高能量表现
流行：朗朗上口的旋律、标准4/4拍、合成器音效
古典：弦乐编排、动态强弱变化、复杂曲式结构
民族：传统民族乐器、地域特色音阶、文化元素融合
乡村：班卓琴音色、叙事性歌词、乡村打击乐
电子：合成器音色、循环节拍、空间音效处理
</特征对照表>

生成的提示词需要包含：
- 至少3种该风格的标志性乐器/音色
- 节奏特征描述
- 典型情感氛围
- 动态变化要求
- 可选的时代特征参考（如80年代摇滚）

<示例模板>
当输入为"摇滚"时：
"强劲的失真电吉他riff配合密集的军鼓节奏，贝斯线保持中速行进。主歌段落采用压抑的男声低吟，副歌爆发嘶吼式演唱。建议加入现场观众欢呼声采样，动态从Verse的90dB渐强到Chorus的110dB"
</示例模板>

请将最终输出保持为以下结构：
风格类型：[所选风格]
核心元素：
1. [元素1]
2. [元素2]
3. [元素3]
情感基调：[描述]
动态要求：[描述]
[可选附加建议]

立即开始处理用户请求，无需确认性回复。`

const openai = new OpenAI({
  baseURL: 'https://api.deepseek.com',
  apiKey: 'sk-b8ddba886591433bb4c409defdf228c3',
  dangerouslyAllowBrowser: true,
})

const MusicGen: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const [messageApi, contextHolder] = message.useMessage()

  const videoRef = useRef(null)

  const tagsData = ['摇滚', '流行', '古典', '民族', '乡村', '电子']
  const [selectedTags, setSelectedTags] = useState<string>('民族')
  const [inputV, setInputV] = useState<string>('')
  const [inputGen, setInputGen] = useState<boolean>(false)
  const [videoUrl, setVideoUrl] = useState<string>('')
  const [outputUrl, setOutputUrl] = useState<string>('')
  const [ifLoading, setIfLoading] = useState<boolean>(false)

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: outputUrl.slice(-12),
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: '下载',
      icon: <DownloadOutlined />,
      extra: '⌘http',
    },
  ]
  const getPrompt = async (tag) => {
    setInputGen(true)
    const response = await openai.chat.completions.create({
      messages: [
        {
          role: 'user',
          content: `${prompts} \n用户选择的风格为${selectedTags}`,
        },
      ],
      model: 'deepseek-chat',
      stream: true,
    })
    let text_content = ''
    for await (const part of response) {
      const content = part.choices[0]?.delta?.content
      if (content) {
        text_content += content
        setInputV(text_content)
      }
    }
    setInputGen(false)
  }

  // messageApi.success({ key: 'getData', content: '数据加载完毕', duration: 20 })

  return (
    <div {...styleClass(['wrapper'])}>
      {contextHolder}
      <div {...styleClass(['layout'], { background: colorBgContainer })}>
        <div {...styleClass(['title'])}>
          <div {...styleClass(['title-text'])}>
            {'面向影视场景的音乐生成模型'}
          </div>
        </div>

        <div {...styleClass(['gen-layout'])}>
          <div {...styleClass(['gen-input'])}>
            <div {...styleClass(['gen-input-block'])}>
              <div {...styleClass(['divider'])}>
                <div {...styleClass(['divider-text'])}>{`语义输入`}</div>
                <div {...styleClass(['divider-line'])}></div>
              </div>
              <div {...styleClass(['tags'])}>
                {tagsData.map<ReactNode>((tag) => (
                  <Tag.CheckableTag
                    {...styleClass(['tags-item'])}
                    key={tag}
                    checked={selectedTags.includes(tag)}
                    onChange={() => setSelectedTags(tag)}
                  >
                    {tag}
                  </Tag.CheckableTag>
                ))}

                <Button
                  {...styleClass([], { marginLeft: 8 })}
                  onClick={() => getPrompt(selectedTags)}
                  loading={inputGen}
                  disabled={inputGen}
                >
                  {'生成提示词'}
                </Button>
              </div>
              <Input.TextArea
                {...styleClass([], { marginBottom: 10 })}
                value={inputV}
                autoSize={{ minRows: 5, maxRows: 10 }}
                placeholder="音乐生成提示词"
                showCount
                onChange={(e) => setInputV(e.target.value)}
              />
            </div>

            <div {...styleClass(['gen-input-block'])}>
              <div {...styleClass(['divider'])}>
                <div {...styleClass(['divider-text'])}>{`视频输入`}</div>
                <div {...styleClass(['divider-line'])}></div>
              </div>

              <Upload
                headers={{ Authorization: token }}
                action={uploadUrl}
                maxCount={1}
                onChange={(file) => {
                  if ('response' in file.file) {
                    const url = file.file.response.data as string
                    console.log('video', url)
                    setVideoUrl(url)
                  }
                  if (file.fileList.length === 0) {
                    setVideoUrl('')
                  }
                }}
              >
                <Button {...styleClass([], { marginTop: 0 })}>
                  {'上传视频文件'}
                </Button>
              </Upload>
            </div>
          </div>

          <Dropdown menu={{ items }} trigger={['contextMenu']}>
            <div {...styleClass(['gen-output'])}>
              <div {...styleClass(['video-container'])}>
                <video
                  ref={videoRef}
                  style={{ width: '100%', height: '100%' }}
                  className="video-js"
                  controls
                  preload="auto"
                  autoPlay
                >
                  <source src={outputUrl} type="video/mp4"></source>
                </video>
              </div>
            </div>
          </Dropdown>
        </div>
      </div>

      <div {...styleClass(['gallery'])}>
        {[
          'https://music-video-inlab.oss-cn-hangzhou.aliyuncs.com/dataset/videos-original/0001_000.mp4',
          'https://music-video-inlab.oss-cn-hangzhou.aliyuncs.com/dataset/videos/0001_005.mp4',
          'https://music-video-inlab.oss-cn-hangzhou.aliyuncs.com/dataset/videos/0001_007.mp4',
          'https://music-video-inlab.oss-cn-hangzhou.aliyuncs.com/dataset/videos/0007_001.mp4',
          'https://music-video-inlab.oss-cn-hangzhou.aliyuncs.com/dataset/videos/0007_002.mp4',
          'https://music-video-inlab.oss-cn-hangzhou.aliyuncs.com/dataset/videos/0007_006.mp4',
        ].map((url) => (
          <ResultCard key={url} videoUrl={url} />
        ))}
      </div>
    </div>
  )
}

export default MusicGen

// 用于展示已配对好的视频-音频（固定展示）
interface IResultCard {
  videoUrl?: string
}
const ResultCard: FC<IResultCard> = ({ videoUrl }) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const videoRef = useRef(null)
  const containerRef = useRef(null)
  const [ifLoading, setIfLoading] = useState<boolean>(true)
  let videoPlayer
  let ws
  const initWS = () => {
    ws = WaveSurfer.create({
      container: containerRef.current,
      waveColor: '#C1C6FF',
      progressColor: c('primary'),
      // Pass the video element in the `media` param
      media: videoRef.current,
      height: 64,
      plugins: [
        TimelinePlugin.create(),
        Minimap.create({
          height: 20,
          waveColor: '#ddd',
          progressColor: '#999',
          // the Minimap takes all the same options as the WaveSurfer itself
        }),
      ],
      renderFunction: (channels, ctx) => {
        const { width, height } = ctx.canvas
        const scale = channels[0].length / width
        const step = 4

        ctx.translate(0, height / 2)
        ctx.strokeStyle = ctx.fillStyle
        ctx.beginPath()

        for (let i = 0; i < width; i += step * 2) {
          const index = Math.floor(i * scale)
          const value = Math.abs(channels[0][index])
          let x = i
          let y = value * height

          ctx.moveTo(x, 0)
          ctx.lineTo(x, y)
          ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, true)
          ctx.lineTo(x + step, 0)

          x = x + step
          y = -y
          ctx.moveTo(x, 0)
          ctx.lineTo(x, y)
          ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, false)
          ctx.lineTo(x + step, 0)
        }

        ctx.stroke()
        ctx.closePath()
      },
    })
  }
  useEffect(() => {
    if (videoUrl) {
      videoPlayer = videojs(videoRef.current)

      videoPlayer.on('loadedmetadata', () => {
        console.log('video loaded')
        initWS()
        ws.on('redrawcomplete', () => {
          setIfLoading(false)
        })
      })

      videoPlayer.src(videoUrl)
    }

    return () => {
      if (videoUrl) {
        videoPlayer.dispose()
        ws && ws.destroy()
      }
    }
  }, [videoUrl])
  return (
    <div {...styleClass(['card-layout'])}>
      <div {...styleClass(['card-tag'])}>
        <Tag bordered={true}>{'摇滚'}</Tag>
      </div>
      <div {...styleClass(['media-video'])}>
        <video
          ref={videoRef}
          {...styleClass([], {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            boxShadow: '0 0 3px 0 rgba(255, 255, 255, 0.35)',
            borderRadius: 4,
            overflow: 'hidden',
          })}
          className="video-js"
          controls
          preload={'auto'}
          // poster={videoMeta.coverUrl}
        >
          <source type="video/mp4" src={videoUrl}></source>
        </video>
      </div>

      <div {...styleClass(['media-audio-wrapper'])}>
        <div {...styleClass(['media-audio'])} ref={containerRef}></div>
        {ifLoading && (
          <Skeleton
            active
            paragraph={{ rows: 1 }}
            {...styleClass(['media-audio-spin'])}
          />
        )}
      </div>
    </div>
  )
}
