import { FC, useEffect, useRef } from 'react'
import { useColorVar, useStyles } from '@/hooks/styles'
import styles from '@/components/vis/ScatterPlot.module.scss'
import { useDataOverviewStore } from '@/hooks'
import { Chart } from '@antv/g2'
import { IAnnotationData } from '@/store/dataOverViewStore'
import { observer } from 'mobx-react'
import {
  audioFeature,
  videoFeature,
} from '@/components/vis/HeatMapForAnnotationFeature'

export const ScatterPlot: FC = observer(() => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const containerRef = useRef()
  const dataOverview = useDataOverviewStore()

  const dataScatter = (selectedFeatures: string[], data: IAnnotationData[]) => {
    // 最多选取两个特征，且有两个时必须是1个视频+1个音频
    const showFeatures = selectedFeatures.slice(0, 2)
    if (showFeatures.length === 0) {
      // 用户未选中任何特征的话，默认给叙事节奏和旋律起伏
      showFeatures[0] = '叙事节奏'
      showFeatures[1] = '旋律起伏'
    } else if (showFeatures.length === 2) {
      // 如果两个都是视频或者两个都是音频，则删一个
      if (
        (videoFeature.includes(showFeatures[0]) &&
          videoFeature.includes(showFeatures[1])) ||
        (audioFeature.includes(showFeatures[0]) &&
          audioFeature.includes(showFeatures[1]))
      ) {
        showFeatures.pop()
      }
    }
    const result = []
    data.map((item, index) => {
      if (showFeatures.includes(item.x)) {
        result.push({ video_id: item.video_id, feature: item.x, score: item.y })
      }
    })
    return [result, showFeatures]
  }

  useEffect(() => {
    const container = containerRef.current as HTMLElement
    container.innerHTML = ''

    const chart = new Chart({
      container: container,
      autoFit: true,
      height: 120,
      theme: 'classicDark',
      paddingTop: 0,
      paddingBottom: 8,
    })
    const [data, showFeatures] = dataScatter(
      dataOverview.selectedFeature,
      dataOverview.annotationData as IAnnotationData[]
    )

    chart
      .point()
      .data(data)
      .transform({ type: 'stackY', y1: 'y' })
      .encode('x', (d) => d.score)
      // 视频的特征朝上，音频的朝下
      .encode('y', (d) => (videoFeature.includes(d.feature) ? 1 : -1))
      .encode('color', (d) => (videoFeature.includes(d.feature) ? 1 : 0))
      .scale('color', {
        domain: ['1', '2'],
        range: ['rgb(176, 183, 254)', 'rgb(231, 212, 165)'],
      })
      .encode('shape', 'point')
      .encode('size', 1.25)
      .scale('x', { nice: true })
      .axis('y', {
        title: `← ${showFeatures[1]} · ${showFeatures[0]} →`,
        labelFormatter: (d) => `${Math.abs(+d)}`,
        titleFontSize: 10,
      })
      .axis('x', { title: '分数 →' })
      .legend('color', { title: '视频/音频' })
      .tooltip(false)
    // .tooltip({ channel: 'x', name: '打标分数' })

    chart.lineY().data([0]).style('stroke', 'black')

    chart.render()
  }, [dataOverview.selectedFeature])
  return <div ref={containerRef} {...styleClass(['layout'])}></div>
})
