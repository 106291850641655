import { FC, useEffect, useState } from 'react'
import { useColorVar, useStyles } from '@/hooks/styles'
import styles from '@/components/vis/HeatMapForAnnotationFeature.module.scss'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'
import { useDataOverviewStore } from '@/hooks'
import { observer } from 'mobx-react'
import { mobxProxyTrans } from '@/utils/basic'
import { SvgIcon } from '@/components/icons'
import { HeatMapBlock } from '@/components/icons/HeatMapBlock'
import { Popover, Tooltip } from 'antd'

export const videoFeature = [
  '叙事节奏',
  '叙事内容',
  '形象亲缘',
  '人物张力',
  '场景张力',
  '动作能量',
  '画面唤醒',
  '画面效价',
]
export const audioFeature = [
  '旋律起伏',
  '和声和谐',
  '和声丰富',
  '节奏律动',
  '音色冷暖',
  '音乐唤醒',
  '音乐效价',
]

const low_level_video = [
  '亮区面积占比',
  '暖区面积占比',
  '温度平均值',
  '彩区面积占比',
  '饱和度平均值',
  '质心与几何中心的距离/最大距离',
  '图像水平方向的左右不对称性',
  '区域面积/凸包面积',
  '显著区域到质心的加权标准差',
  '人脸平均愤怒值',
  '人脸平均厌恶值',
  '人脸平均恐惧值',
  '人脸平均开心值',
  '斜视角分值',
  '仰视角分值',
  '航拍高度分值',
  '眼高度分值',
  '地面高度分值',
  '胯高度分值',
  '膝高度分值',
  '肩高度分值',
  '近景分值',
  '远景分值',
  '光流中值',
  '排除相机运动对应像素的光流均值',
  '光流信息熵',
  '排除相机运动对应像素的光流信息熵',
]

const low_level_audio = [
  'MFCC1',
  'MFCC2',
  'MFCC4',
  'MFCC5',
  'MFCC10',
  'MFCC13',
  'Chromagram2',
  'Chromagram4',
  'Chromagram5',
  'Chromagram9',
  'Chromagram10',
  'Chromagram11',
  'Tonalcentroid1',
  'Tonalcentroid3',
  'Tonalcentroid6',
  'Spectralkurtosis',
  'Spectralcentroid',
  'Spectralskewness',
  'Spectralflatness',
  'Spectralspread',
  'Inharmonicity',
  'Eventdensity',
  'RMSenergy',
  'EntropyofSpectrum',
  'Pulseclarity',
  'Brightness',
  'Zerocrossingrate',
]

const blockColor = (mediaType: string, value: number) => {
  const videoPalette = [
    '#2F3848',
    '#3A465B',
    '#45546F',
    '#4F6083',
    '#5A6E96',
    '#647BA9',
    '#7894CD',
    '#8BADF0',
    '#AAC5F9',
    '#B0C6F2',
  ]
  const videoPalette2 = [
    '#3F007D',
    '#3F007D',
    '#54278F',
    '#6A51A3',
    '#807DBA',
    '#9E9AC8',
    '#BCBDDC',
    '#DADAEB',
    '#EFEDF5',
    '#FCFBFD',
  ]

  return videoPalette2[Math.ceil(Math.abs(value) * 10) - 1]
}

export const HeatMapForAnnotationFeature: FC = observer(() => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const dataOverview = useDataOverviewStore()
  const [highlightLowLevel, setHighlightLowLevel] = useState('')
  const [videoData, setVideoData] = useState([])
  const [audioData, setAudioData] = useState([])

  const video_related_data = () => {
    const result = []
    for (let i = 0; i < 8; i++) {
      result.push([])
      for (let j = 0; j < 27; j++) {
        result[i][j] = 0
      }
    }
    // 叙事节奏
    result[0][24] = 0.908
    result[0][23] = -0.522
    result[0][13] = 0.116
    result[0][26] = -0.526
    result[0][21] = -0.11
    result[0][7] = 0.11
    result[0][4] = 0.296
    result[0][3] = -0.197
    result[0][20] = 0.075
    result[0][8] = -0.085
    result[0][16] = 0.092
    result[0][10] = 0.064
    result[0][2] = 0.117
    result[0][25] = 0.43
    result[0][22] = 0.073

    result[1][24] = 0.8
    result[1][23] = -0.449
    result[1][13] = 0.139
    result[1][0] = -0.095
    result[1][4] = 0.234
    result[1][7] = 0.107
    result[1][1] = 0.108
    result[1][12] = 0.093
    result[1][22] = 0.084
    result[1][17] = 0.062
    result[1][26] = -0.059

    result[2][13] = -0.111
    result[2][3] = 0.129
    result[2][7] = -0.14
    result[2][12] = 0.254
    result[2][10] = -0.151
    result[2][2] = -0.103
    result[2][8] = 0.096
    result[2][24] = -0.233
    result[2][23] = 0.151
    result[2][14] = -0.082
    result[2][0] = 0.077
    result[2][9] = -0.171
    result[2][11] = 0.108

    result[3][9] = 0.192
    result[3][16] = 0.276
    result[3][24] = 0.464
    result[3][23] = -0.276
    result[3][7] = 0.112
    result[3][4] = 0.154
    result[3][2] = 0.082
    result[3][20] = 0.097
    result[3][6] = -0.063
    result[3][10] = 0.075
    result[3][13] = 0.087
    result[3][19] = -0.143
    result[3][18] = 0.082

    result[4][24] = 0.679
    result[4][23] = -0.405
    result[4][15] = 0.15
    result[4][7] = 0.103
    result[4][0] = -0.075
    result[4][4] = 0.417
    result[4][3] = -0.295
    result[4][2] = 0.103
    result[4][13] = 0.157
    result[4][21] = -0.097
    result[4][5] = 0.088
    result[4][6] = -0.067

    result[5][24] = 0.829
    result[5][23] = -0.469
    result[5][13] = 0.119
    result[5][21] = -0.181
    result[5][7] = 0.114
    result[5][12] = 0.158
    result[5][1] = 0.066
    result[5][4] = 0.21
    result[5][3] = -0.147
    result[5][8] = -0.048
    result[5][0] = -0.061
    result[5][11] = -0.066

    result[6][24] = 0.72
    result[6][23] = -0.396
    result[6][13] = 0.185
    result[6][0] = -0.091
    result[6][4] = 0.187
    result[6][10] = 0.132
    result[6][21] = -0.103
    result[6][7] = 0.104
    result[6][8] = -0.08
    result[6][1] = 0.068
    result[6][3] = -0.111

    result[7][0] = 0.073
    result[7][13] = -0.093
    result[7][7] = -0.154
    result[7][3] = 0.107
    result[7][2] = -0.095
    result[7][10] = -0.18
    result[7][12] = 0.273
    result[7][24] = -0.162
    result[7][22] = 0.05
    result[7][23] = 0.099
    result[7][9] = -0.115
    result[7][8] = 0.078
    result[7][14] = -0.066

    let min = 0.5
    let max = 0.5
    for (let i = 0; i < 8; i++) {
      for (let j = 0; j < 27; j++) {
        if (result[i][j] === 0) continue // 最小最大值暂不考虑0
        if (Math.abs(result[i][j]) < min) {
          min = Math.abs(result[i][j])
        }
        if (Math.abs(result[i][j]) > max) {
          max = Math.abs(result[i][j])
        }
      }
    }
    for (let i = 0; i < 8; i++) {
      for (let j = 0; j < 27; j++) {
        if (result[i][j] === 0) continue // 最小最大值暂不考虑0
        result[i][j] =
          result[i][j] > 0
            ? ((result[i][j] - min) / (max - min)) * (1 - 0.25) + 0.25
            : -(
                ((Math.abs(result[i][j]) - min) / (max - min)) * (1 - 0.55) +
                0.55
              )
      }
    }
    // console.log(min, max, result)
    return result
  }

  const audio_related_data = () => {
    const result = []
    for (let i = 0; i < 7; i++) {
      result.push([])
      for (let j = 0; j < 27; j++) {
        result[i][j] = 0
      }
    }
    // 旋律起伏
    result[0][11] = 0.075
    result[0][5] = -0.058
    // 和声和谐
    result[1][21] = -0.097
    result[1][3] = -0.067
    result[1][15] = 0.059
    result[1][22] = 0.057
    // 和声丰富
    result[2][20] = -0.111
    result[2][4] = 0.129
    result[2][9] = -0.14
    // 节奏律动
    result[3][23] = -0.092
    result[3][6] = 0.096
    result[3][14] = 0.076
    result[3][13] = 0.065
    // 音色冷暖
    result[4][15] = -0.051
    // 音色唤醒
    result[5][23] = 0.22
    result[5][22] = 0.16
    result[5][16] = -0.16
    result[5][17] = 0.16
    result[5][24] = 0.065
    result[5][4] = 0.057
    result[5][2] = 0.053
    result[5][18] = -0.12
    result[5][19] = 0.1
    result[5][0] = -0.15
    result[5][25] = -0.145
    result[5][26] = 0.108
    result[5][10] = 0.098
    result[5][5] = 0.051
    result[5][14] = -0.083
    result[5][7] = 0.057
    result[5][8] = -0.041
    result[5][4] = 0.034
    // 音色效价
    result[6][24] = 0.132
    result[6][12] = 0.088
    result[6][8] = -0.09
    result[6][19] = 0.058
    result[6][0] = 0.179
    result[6][16] = 0.145
    result[6][15] = -0.097
    result[6][1] = -0.068
    result[6][3] = 0.049

    let min = 0.5
    let max = 0.5
    for (let i = 0; i < 7; i++) {
      for (let j = 0; j < 27; j++) {
        if (result[i][j] === 0) continue // 最小最大值暂不考虑0
        if (Math.abs(result[i][j]) < min) {
          min = Math.abs(result[i][j])
        }
        if (Math.abs(result[i][j]) > max) {
          max = Math.abs(result[i][j])
        }
      }
    }
    for (let i = 0; i < 7; i++) {
      for (let j = 0; j < 27; j++) {
        if (result[i][j] === 0) continue // 最小最大值暂不考虑0
        result[i][j] =
          result[i][j] > 0
            ? ((result[i][j] - min) / (max - min)) * (1 - 0.35) + 0.35
            : -(
                ((Math.abs(result[i][j]) - min) / (max - min)) * (1 - 0.55) +
                0.55
              )
      }
    }
    // console.log(min, max, result)
    return result
  }

  useEffect(() => {
    // console.log(mobxProxyTrans(dataOverview.hoveredFeature))
  }, [dataOverview.hoveredFeature])

  useEffect(() => {
    const result1 = video_related_data()
    const result2 = audio_related_data()

    setVideoData(result1)
    setAudioData(result2)
  }, [])

  return (
    <div {...styleClass(['layout'])}>
      {['视频', '音频'].map((mediaType) => (
        <div key={mediaType} {...styleClass(['container'])}>
          <div
            {...styleClass(['container-left-bar'], {
              background: mediaType === '视频' ? '#B0B7FE' : '#E7D4A5',
            })}
          ></div>
          <div {...styleClass(['heat-map'])}>
            <div
              {...styleClass(['heat-map-label-layout'], {
                ...(mediaType === '视频' ? { top: -30 } : { bottom: -28 }),
                marginLeft: mediaType === '视频' ? 44 : 47,
              })}
            >
              {(mediaType === '视频' ? low_level_video : low_level_audio).map(
                (low_level_name, index) => (
                  <motion.div
                    key={low_level_name}
                    {...styleClass(['heat-map-label-item'], {
                      transform:
                        mediaType === '视频'
                          ? 'rotate(-60deg)'
                          : 'rotate(60deg)',
                    })}
                    animate={{
                      opacity: low_level_name === highlightLowLevel ? 1 : 0.15,
                    }}
                    transition={{ ...motionEasing }}
                  >
                    {low_level_name}
                  </motion.div>
                )
              )}
            </div>
            <div {...styleClass(['heat-map-matrix'])}>
              {(mediaType === '视频' ? videoFeature : audioFeature).map(
                (featureName, featureIndex) => (
                  <motion.div
                    key={featureName}
                    {...styleClass(['heat-map-matrix-line'])}
                    onMouseEnter={() =>
                      dataOverview.setHoveredFeature(featureName, 'in')
                    }
                    onMouseLeave={() =>
                      dataOverview.setHoveredFeature(featureName, 'out')
                    }
                    onClick={() => dataOverview.setSelectedFeature(featureName)}
                    animate={{
                      background:
                        dataOverview.hoveredFeature.indexOf(featureName) >= 0 ||
                        dataOverview.selectedFeature.indexOf(featureName) >= 0
                          ? 'rgba(255,255,255,0.2)'
                          : 'rgba(255,255,255,0)',
                    }}
                    transition={{ ...motionEasing }}
                  >
                    <motion.div
                      {...styleClass(['heat-map-matrix-line-left'])}
                      animate={{
                        opacity:
                          dataOverview.hoveredFeature.indexOf(featureName) >=
                            0 ||
                          dataOverview.selectedFeature.indexOf(featureName) >= 0
                            ? 0.85
                            : 0.25,
                      }}
                      transition={{ ...motionEasing }}
                    >
                      <div {...styleClass(['heat-map-matrix-line-left-text'])}>
                        {featureName}
                      </div>
                      <div
                        {...styleClass(['heat-map-matrix-line-left-bar'])}
                      ></div>
                    </motion.div>

                    <div {...styleClass(['heat-map-matrix-line-right'])}>
                      {(mediaType === '视频' ? videoData : audioData).length !==
                        0 &&
                        (mediaType === '视频' ? videoData : audioData)[
                          featureIndex
                        ].map((value, index) => (
                          <Block
                            key={`${
                              (mediaType === '视频'
                                ? videoFeature
                                : audioFeature)[featureIndex]
                            }-${
                              (mediaType === '视频'
                                ? low_level_video
                                : low_level_audio)[index]
                            }`}
                            value={value}
                            featureName={
                              (mediaType === '视频'
                                ? videoFeature
                                : audioFeature)[featureIndex]
                            }
                            lowFeatureName={
                              (mediaType === '视频'
                                ? low_level_video
                                : low_level_audio)[index]
                            }
                            onMouseEnter={() =>
                              setHighlightLowLevel(
                                (mediaType === '视频'
                                  ? low_level_video
                                  : low_level_audio)[index]
                              )
                            }
                            onMouseLeave={() => setHighlightLowLevel('')}
                          />
                        ))}
                    </div>
                  </motion.div>
                )
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
})

interface IBlock {
  value: number
  featureName?: string // 视听感知特征
  lowFeatureName?: string // 底层特征
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onClick?: () => void
}

const Block: FC<IBlock> = ({
  value,
  featureName,
  lowFeatureName,
  onMouseEnter,
  onMouseLeave,
}) => {
  const styleClass = useStyles(styles)
  const dataOverview = useDataOverviewStore()

  const calcStyle = (value: number, ratio: number, c: number) => {
    return Math.abs(value) * ratio + c >= 1 ? 1 : Math.abs(value) * ratio + c
  }

  return (
    <Popover
      content={`${featureName} - ${lowFeatureName}: ${value.toFixed(2)}`}
      destroyTooltipOnHide={true}
    >
      <div
        {...styleClass(['block-layout'], {})}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          {...styleClass(['block-layout'], {
            opacity: calcStyle(value, 1.3, 0.2),
            transform: `scale(${calcStyle(value, 1.3, 0.2)}) ${
              value >= 0 ? 'rotate(0deg)' : 'rotate(180deg)'
            }`,
          })}
        >
          <HeatMapBlock color={blockColor('视频', value)} />
        </div>
      </div>
    </Popover>
  )
}
